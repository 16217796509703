import React from "react";
import { BiLike, BiStar } from "react-icons/bi";
import { PiListMagnifyingGlass, PiWechatLogoBold } from "react-icons/pi";
import Banner from "../Banner";
import { MdCampaign, MdGroups2 } from "react-icons/md";
import { TbChartInfographic, TbTargetArrow } from "react-icons/tb";
import { DiHtml53DEffects } from "react-icons/di";
import { Link } from "react-router-dom";
import { GiBoltEye } from "react-icons/gi";
import { MdPriceCheck } from "react-icons/md";
import { HiCursorClick } from "react-icons/hi";
import { SiPagespeedinsights } from "react-icons/si";
import { ImManWoman } from "react-icons/im";
import { MdOutlineDevices } from "react-icons/md";
import { FaSearchLocation } from "react-icons/fa";
import { MdOutlineGroups2 } from "react-icons/md";
import { MdOutlineContentPasteSearch } from "react-icons/md";
// import { TbCategoryPlus } from "react-icons/tb";
import { BiCategoryAlt } from "react-icons/bi";
const TargetingOptions = () =>                                 {                                                                                                
  return (
    <div className="w-full">
      <div className="w-full h-[70vh] md:h-[70vh] bgColor text-white flex flex-col justify-center items-center">
        <h1 className="text-3xl md:text-6xl text-center font-bold mt-16">
        Precision Targeting with BidBox
        </h1>
        <p className="w-[90%] md:text-lg text-center py-5">
        Unlock success in advertising with our DSP's precision targeting. Reach the right audience, maximize engagement, and optimize campaign performance.
        </p>
        <Link to="/contact">
          <button className="mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90">
            Launch a Campaign
          </button>
        </Link>
      </div>
      <div className="w-full pt-10 flex flex-col items-center">
        <div className="w-[95%] md:flex md:justify-between">
          <div className="w-full md:w-[45%] flex flex-col items-center md:items-start justify-center">
            <h1 className="text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3">
            The Strategic Significance of Targeting in DSP
            </h1>
            <div className="w-full md:hidden md:w-[50%]">
              <img
                className="w-[100%] h-[100%] object-cover"
                src="/video1.jpg"
                alt="imagee"
              />
            </div>
            <p className="text-base md:text-lg pt-5 text-center md:text-start text-black">
            In the realm of DSP, effective targeting is the linchpin for campaign success. It empowers advertisers to hone in on specific demographics, behaviors, and preferences, amplifying the relevance of their messages. 
            </p>
            <p className="text-base md:text-lg pb-5 text-center md:text-start text-black pt-2">
            This precision not only enhances user engagement but also ensures optimal resource allocation, driving cost-efficiency and boosting overall campaign performance.
            </p>
            <div className="mt-5">
              <a href="/contact">
                <button className="rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5">
                  Let&apos;s Start
                </button>
              </a>
            </div>
          </div>
          <div className="w-full hidden md:block md:w-[50%]">
            <img
              className="w-[100%] h-[100%] object-cover"
              src="/video1.jpg"
              alt="imagee"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center">
        <div className="w-[95%] flex flex-col my-20 justify-center">
          <h1 className="text-center text-3xl md:text-6xl textColor font-bold">
            Diverse Targeting Options
          </h1>

          <div className="w-full flex flex-wrap md:flex-nowrap gap-14 mt-14">
            <div className="w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl">
              <div className="w-20 h-20">
                <ImManWoman className="text-7xl textColor" />
              </div>
              <h1 className="font-bold text-2xl text-center">
                Demographic Targeting
              </h1>
              <p className="textColor font-medium text-center">
                Tailor campaigns with laser-focused precision, targeting
                specific genders and age groups.
              </p>
            </div>
            <div className="w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl">
              <div className="w-20 h-20">
                <MdOutlineDevices className="text-7xl textColor" />
              </div>
              <h1 className="font-bold text-2xl text-center">
                Platform & Carrier Targeting
              </h1>
              <p className="textColor font-medium text-center">
                Optimize reach with tailored campaigns based on OS and carrier
                settings.
              </p>
            </div>
            <div className="w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl">
              <div className="w-20 h-20">
                <BiCategoryAlt className="text-7xl textColor" />
              </div>
              <h1 className="font-bold text-2xl text-center">
                {" "}
                Multi-Channel Reach
              </h1>
              <p className="textColor font-medium text-center">
                Reach diverse audiences seamlessly across mobile, desktop,
                tablet, and CTV channels.
              </p>
            </div>
          </div>

          <div className="w-full flex flex-wrap md:flex-nowrap gap-14 mt-14">
            <div className="w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl">
              <div className="w-22 h-22">
                <MdOutlineGroups2 className="text-8xl textColor" />
              </div>
              <h1 className="font-bold text-2xl text-center">
                AI-Driven Cohort Targeting
              </h1>
              <p className="textColor font-medium text-center">
                Leverage advanced AI algorithms to target over 50 diverse
                cohorts intelligently.
              </p>
            </div>
            <div className="w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl">
              <div className="w-20 h-20">
                <FaSearchLocation className="text-7xl textColor" />
              </div>
              <h1 className="font-bold text-2xl text-center">
                Geographical Precision
              </h1>
              <p className="textColor font-medium text-center">
                Refine campaigns with precise city-level targeting for optimal
                audience engagement.
              </p>
            </div>
            <div className="w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl">
              <div className="w-22 h-22">
                <MdOutlineContentPasteSearch className="text-7xl textColor" />
              </div>
              <h1 className="font-bold text-2xl text-center">
                Dynamic Audience Crafting
              </h1>
              <p className="textColor font-medium text-center">
                Elevate future campaigns with personalized audiences, refined
                through insightful analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col items-center'>

                    <h1 className='pt-14 text-3xl md:text-5xl text-center font-bold md:w-[60%]'>Revolutionize Your DSP Needs with BidBox Solutions Today</h1>
                    <p className='py-5 pb-7 text-center text-xl font-bold textColor'>Our designers make your ideas happen based on data that comes from hundreds of successful campaigns.</p>

                    <div className='flex flex-wrap md:flex-nowrap w-full justify-between gap-10 my-10'>
                        <div className='w-full hover:bg-gradient-to-r from-purple-600 to-blue-600 hover:text-white textColor flex flex-col items-center rounded-3xl p-2 py-3 shadow-2xl hover:shadow-2xl'>
                            <PiWechatLogoBold className='text-5xl mt-2' />
                            <h2 className='text-center text-2xl font-semibold pt-3'>Audience Accuracy</h2>
                            <p className='text-center pt-1'>Targeting ensures your message reaches the right audience.</p>
                        </div>
                        <div className='w-full bg-gradient-to-r from-purple-600 to-blue-600 text-white flex flex-col items-center rounded-3xl p-2 md:scale-105 shadow-2xl'>
                            <BiLike className='text-5xl mt-2' />
                            <h2 className='text-center text-2xl font-semibold pt-3'>ROI Maximization</h2>
                            <p className='text-center pt-1'>Maximize ROI by focusing on relevant, interested prospects.</p>
                        </div>
                        <div className='w-full hover:bg-gradient-to-r from-purple-600 to-blue-600 hover:text-white textColor flex flex-col items-center rounded-3xl p-2 shadow-2xl hover:shadow-2xl'>
                            <BiStar className='text-5xl mt-2' />
                            <h2 className='text-center text-2xl font-semibold pt-3'>Connection Boost</h2>
                            <p className='text-center pt-1'>Tailored content boosts customer connection and campaign success.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='w-full my-10 bg-white flex justify-center'>
                <div className='w-[95%] flex flex-col items-center my-10'>

                    <div className='flex w-full md:my-10'>
                        <div className='w-[20%] hidden md:block'>
                            <img src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/649bccc5e46ebc6622d5fb26_Darius%20Tara%C5%A1kevi%C4%8Dius-p-500.png' alt='imgg' />
                        </div>
                        <div className='md:w-[70%] flex flex-col px-3 md:px-10'>
                            <p className='font-bold text-gray-500/90'>CASE STUDY</p>
                            <h2 className='py-5 text-3xl md:text-5xl font-bold'>Bidbox: Elevate Your Campaigns with Precision Solutions</h2>
                            <p className='text-lg textColor'>
                                Leveraging programmatic strategies, we deliver inventive campaigns, collaborating seamlessly with Bidbox. We appreciate the mutual challenges that drive us to craft optimal solutions, ensuring we surpass goals and exceed client expectations.
                            </p>
                        </div>
                    </div>

                </div>
            </div> */}

      <Banner />
    </div>
  );
};

export default TargetingOptions;
