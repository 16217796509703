import React from 'react'
import Banner from '../Banner'
import { FaDollarSign } from "react-icons/fa6";
import { MdDevices } from "react-icons/md";
import { RxLapTimer } from "react-icons/rx";
import { PiTargetFill } from "react-icons/pi";
import { MdOutlineHighQuality } from "react-icons/md";
import { IoPeopleSharp } from "react-icons/io5";
import { Link } from 'react-router-dom'

const Ctv = () => {
    return (
        <div className='w-full'>

            <div className='w-full h-[90vh] md:h-[70vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl text-center md:text-6xl font-bold mt-16'>Exploring Connected TV Advertising</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>
                    Connected TV (CTV) advertising, in conjunction with a Demand-Side Platform (DSP), represents a dynamic intersection of technology and marketing. DSPs allow advertisers to programmatically target and deliver video ads to CTV viewers, leveraging data-driven insights for precise audience targeting. 
                </p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full pt-10 flex flex-col items-center'>

                <div className='w-[95%] md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                        CTV reach in India
                        </h1>

                        <div className='w-full md:hidden md:w-[50%]'>
                            <img className='w-[100%] h-[100%] object-cover' src='/CTVAds.jpg' alt='imagee' />
                        </div>

                        <p className='text-base md:text-lg text-center md:text-start pt-5 md:py-5 text-black'>
                        Examining the growth trajectory of Connected TV (CTV) in households, the count stood at 10-12 million in January 2022, surged to 23-25 million in January 2023, and surpassed 35 million by June 2023. Anticipated projections suggest this figure will further rise to 40 million by 2025.
                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>
                    <div className='w-full hidden md:block md:w-[50%]'>
                        <img className='w-[100%] h-[100%] object-cover' src='/CTVAds.jpg' alt='imagee' />
                    </div>
                </div>

                <div className='w-[95%] mt-10 md:flex md:justify-between]'>
                    <div className='md:w-[55%] hidden md:flex'>
                        <img className='w-[100%] h-[100%] object-cover' src='/CTV3.jpg' alt='image2' />
                    </div>

                    <div className='md:w-[40%] px-5 flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                        Synced Household Advertising

                        </h1>

                        <div className='md:w-[60%] md:hidden'>
                            <img className='w-[100%] h-[100%] object-cover' src='/CTV3.jpg' alt='image2' />
                        </div>

                        <p className='text-base md:text-lg text-center md:text-start pt-5 md:py-5 text-black'>
                        Facilitate seamless interactions with your audience using our Household Sync feature. Boost your brand's influence by synchronizing your CTV ads with advertisements on other devices within the household, leading to increased visibility and reduced CPMs.
                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>


                </div>

            </div>

            <div className='w-full md:h-[95vh] bg-gradient-to-r from-purple-600 to-blue-600 py-10 my-10 flex flex-col justify-center items-center'>
                <div className='w-[95%] text-white md:py-20 flex flex-col items-center'>
                    <h2 className='text-center text-3xl md:text-6xl font-bold'>Diverse Display Ad Formats</h2>
                    <p className='md:w-[80%] py-5 md:py-10 text-gray-100 font-medium text-xl text-center'>Amplify audience engagement with our team&apos;s professionally designed dynamic and interactive ads or select from our cutting-edge ready-to-use templates.</p>
                    <div className='w-full flex justify-center flex-wrap gap-4 md:gap-8'>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Display Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Video Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Native Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Dynamic Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Rich Media Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Roadblock Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interstitial Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interactive Ad</div>
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col md:my-20 justify-center'>
                    <h1 className='text-center text-3xl md:text-6xl textColor font-bold'>Why Connected TV Advertising?</h1>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[250px] max-h-[400px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <MdDevices className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>CTV Household Sync</h1>
                            <p className='textColor font-medium text-center'>Maximize brand reach with CTV Sync: harmonize campaigns across screens, boost engagement, and welcome the future of impactful CTV advertising.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[400px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <PiTargetFill className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Precise Screen Targeting</h1>
                            <p className='textColor font-medium text-center'>Connect with today's hyper-connected users through CTV ads. Utilize demographic, interest, or location data for targeted campaigns from 1st or 3rd party sources.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[400px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <MdOutlineHighQuality className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>High Quality Ad-formats</h1>
                            <p className='textColor font-medium text-center'>High-quality visuals and audio on CTV create immersive ad experiences, capturing viewer attention more effectively than traditional methods.</p>
                        </div>
                    </div>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[250px] max-h-[400px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <RxLapTimer className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Good Video Completion</h1>
                            <p className='textColor font-medium text-center'>With targeted content and engaging formats, CTV reduces viewer tendency to skip ads, ensuring better visibility for brands.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[400px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <IoPeopleSharp className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Responsive Audience</h1>
                            <p className='textColor font-medium text-center'>Viewers' content choices and engagement control foster satisfaction, leading to better perceptions and attitudes toward ads.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[400px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <FaDollarSign className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Cost-effectivity</h1>
                            <p className='textColor font-medium text-center'>Connected TVs optimize marketing budgets with targeted ads, and performance measurement for efficient and impactful advertising</p>
                        </div>
                    </div>
                </div>
            </div>

            <Banner />
        </div>
    )
}

export default Ctv
