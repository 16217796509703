import React from "react";
import CaseStudyCard from "./CaseStudyCard";

const Automobile = () => {
  return (
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold text-center mt-14">
          Automobile Industry
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/jeepnew.jpg"
                heading="SUV Enthusiasts Unite: Jeep Meridian"
                impression="Impressions - 18 Million+"
                vtr="Showroom Visit - 3k Approx"
                content="Our objective for JEEP was clear: to reach and engage premium customers. Through a meticulously crafted approach, we employed city-wise targeting, focusing on tier 1 cities to ensure we reached the right audience. Additionally, we targeted  iOS users, along with automotive cohorts."
                content1="The result of our campaign exceeded expectations, with over 18 million impressions achieved. However, the true success lay in the impact on potential customers. In just two months, we generated nearly 3,000 showroom visits for test drives, proving the effectiveness of our strategy and the allure of the Jeep Meridian."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
              <CaseStudyCard
                image="/citrorennew.jpg"
                heading="Strategic Brilliance: Citroen C3 Success"
                impression="Website Visits - 158000+"
                vtr="CTR - 1.2%"
                content="Employing a carefully tailored strategy, we implemented premium audience targeting, focusing on automotive enthusiasts, in addition to automotive cohorts, we aimed for precision in our campaign. and leveraging autoportals. Geo-targeting was executed in the top 8 metropolitan cities in India to ensure a customized approach."
                content1="The true triumph, however, manifested in consumer impact. Within 1 month of the campaign the brand saw a 50% users surge on website showing the efficacy of our strategy and the allure of the Citroen C3 Aircross."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
              <CaseStudyCard
                image="/Skoda.png"
                heading="Slavia's Resounding Market Impact"
                impression="Impressions: 5 Million+"
                vtr="Reach: 3.2 Million+"
                content="Deploying a mix of impactful display advertisements, attention-grabbing banner ads, and compelling video content across diverse inventories, we achieved remarkable success.The visually appealing banners and engaging videos played a pivotal role in shaping Skoda's brand narrative. Specific Geo Targeting was done for North Indian States."
                content1="This case study exemplifies our adeptness in employing a comprehensive branding strategy, reinforcing Skoda's position as a prominent and resonant brand in the market."
                // content2="This case study exemplifies our adeptness in employing a comprehensive branding strategy, reinforcing Skoda's position as a prominent and resonant brand in the market."
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                     Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automobile;
