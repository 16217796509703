import React from 'react'
import { BsArrowLeftSquareFill, BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const ProceedToBuy = () => {
    return (
        <>
            <div className='w-full bg-gray-100'>
                <div className='w-full flex flex-col items-center'>
                    <div className='w-[90%] my-5'>
                        <Link to="/credit-card">
                            <h3 className='font-medium flex items-center text-gray-600'>
                                <BsArrowLeftSquareFill className='mr-2 text-lg' /> Go Back
                            </h3>
                        </Link>
                    </div>

                    <div className='w-[90%] flex gap-5 justify-between'>

                        <div className='w-full flex flex-col gap-5'>

                            <div className='border rounded-xl shadow-md px-3 py-5 bg-white'>
                                <h2 className='text-xl font-bold'>Cover Amount</h2>
                                <div className='flex items-center'>
                                    <p className='text-sm text-gray-400 mr-2'>
                                        Is this cover amount sufficient?
                                    </p>
                                    <button className='border px-2 py-0.5 shadow-sm bg-green-200 text-sm text-green-500 font-semibold rounded-md'>
                                        Let's find out
                                    </button>
                                </div>
                                <div className='mt-5'>
                                    <select className='border w-[300px] font-medium rounded-lg border-gray-400 py-3.5'>
                                        <option className=''>
                                            <p className='py-5'>₹5 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹10 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹15 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹20 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹25 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹50 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹75 Lakh</p>
                                        </option>
                                        <option className=''>
                                            <p>₹1 Cr</p>
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div className='border rounded-xl shadow-md px-3 py-5 bg-white'>
                                <h2 className='text-xl font-bold'>Policy Period</h2>
                                <div className='flex items-center'>
                                    <p className='text-sm text-gray-400 mr-2'>
                                        Choosing a multi-year plan saves your money and the trouble of remembering yearly renewals.
                                    </p>
                                </div>

                                <div className='mt-5 flex gap-5'>
                                    <div className='w-[200px] h-[80px] cursor-pointer border-green-500 flex justify-center items-center rounded-lg border-2'>
                                        <p className='text-gray-500'>1 Year @ <span className='text-gray-900 font-bold'>₹5,414</span></p>
                                    </div>
                                    <div className='w-[200px] h-[80px] cursor-pointer flex justify-center border-gray-400 items-center rounded-lg border'>
                                        <p className='text-gray-500'>2 Year @ <span className='text-gray-900 font-bold'>₹10,287</span></p>
                                    </div>
                                    <div className='w-[200px] h-[80px] cursor-pointer flex justify-center border-gray-400 items-center rounded-lg border'>
                                        <p className='text-gray-500'>3 Year @ <span className='text-gray-900 font-bold'>₹15,024</span></p>
                                    </div>
                                </div>

                            </div>

                            <div className='border rounded-xl shadow-md px-3 py-5 bg-white'>
                                <h2 className='text-xl font-bold'>Riders</h2>
                                <div className='flex items-center'>
                                    <p className='text-sm text-gray-400 mr-2'>
                                        You should get these additional benefits to enhance your current plan
                                    </p>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className='w-[65%]'>
                                            <h2 className='text-black font-bold'>
                                                Unlimited Automatic Restoration of Sum Insured
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Get up to 100% unlimited restoration of sum insured each time upon partial/ full utilization of the sum insured
                                            </p>
                                        </div>
                                        <div className='w-[30%] flex justify-end'>
                                            <div>
                                                <button className='border hover:border-2 flex items-center pr-4 pl-2 py-1 border-green-500 text-green-500 font-medium rounded-lg'><BsPlus className='text-xl' /> Add</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className='w-[65%]'>
                                            <h2 className='text-black font-bold'>
                                                Modification of Room Category
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Get up to 100% unlimited restoration of sum insured each time upon partial/ full utilization of the sum insured
                                            </p>
                                        </div>
                                        <div className='w-[30%] flex justify-end'>
                                            <div>
                                                <button className='border hover:border-2 flex items-center pr-4 pl-2 py-1 border-green-500 text-green-500 font-medium rounded-lg'><BsPlus className='text-xl' /> Add</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className='w-[65%]'>
                                            <h2 className='text-black font-bold'>
                                                Non-Medical Items (Consumables) cover
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Get up to 100% unlimited restoration of sum insured each time upon partial/ full utilization of the sum insured
                                            </p>
                                        </div>
                                        <div className='w-[30%] flex justify-end'>
                                            <div>
                                                <button className='border hover:border-2 flex items-center pr-4 pl-2 py-1 border-green-500 text-green-500 font-medium rounded-lg'><BsPlus className='text-xl' /> Add</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            <div className='border rounded-xl shadow-md px-3 py-5 bg-white'>
                                <h2 className='text-xl font-bold'>Riders</h2>
                                <div className='flex items-center'>
                                    <p className='text-sm text-gray-400 mr-2'>
                                        You should get these additional benefits to enhance your current plan
                                    </p>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className='w-[65%]'>
                                            <h2 className='text-black font-bold'>
                                                Unlimited Automatic Restoration of Sum Insured
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Get up to 100% unlimited restoration of sum insured each time upon partial/ full utilization of the sum insured
                                            </p>
                                        </div>
                                        <div className='w-[30%] flex justify-end'>
                                            <div>
                                                <button className='border hover:border-2 flex items-center pr-4 pl-2 py-1 border-green-500 text-green-500 font-medium rounded-lg'><BsPlus className='text-xl' /> Add</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className='w-[65%]'>
                                            <h2 className='text-black font-bold'>
                                                Modification of Room Category
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Get up to 100% unlimited restoration of sum insured each time upon partial/ full utilization of the sum insured
                                            </p>
                                        </div>
                                        <div className='w-[30%] flex justify-end'>
                                            <div>
                                                <button className='border hover:border-2 flex items-center pr-4 pl-2 py-1 border-green-500 text-green-500 font-medium rounded-lg'><BsPlus className='text-xl' /> Add</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className='w-[65%]'>
                                            <h2 className='text-black font-bold'>
                                                Non-Medical Items (Consumables) cover
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Get up to 100% unlimited restoration of sum insured each time upon partial/ full utilization of the sum insured
                                            </p>
                                        </div>
                                        <div className='w-[30%] flex justify-end'>
                                            <div>
                                                <button className='border hover:border-2 flex items-center pr-4 pl-2 py-1 border-green-500 text-green-500 font-medium rounded-lg'><BsPlus className='text-xl' /> Add</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='w-[500px] relative'>
                            <div className='border sticky top-5 text-gray-700 rounded-xl shadow-md px-5 py-3 bg-white'>
                                <h2 className='font-bold text-xl'>Summary</h2>
                                <div className='flex justify-between mt-3'>
                                    <p className='text-sm text-gray-500'>Base Premium - 1 year</p>
                                    <p className='font-bold text-sm'>5,414</p>
                                </div>

                                <div className='my-3'>
                                    <h3 className='font-bold pb-2'>Select Rider(s)</h3>
                                    <div className='border border-dashed border-gray-300 flex justify-between px-3 py-3'>
                                        <p className='text-sm text-gray-500'>Missing out on benefits</p>
                                        <p className='text-sm text-green-500 font-medium'>View riders</p>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <h3 className='font-bold pb-2'>Select Add-ons</h3>
                                    <div className='border border-dashed border-gray-300 flex justify-between px-3 py-3'>
                                        <p className='text-sm text-gray-500'>No add-ons selected</p>
                                        <p className='text-sm text-green-500 font-medium'>View riders</p>
                                    </div>
                                </div>

                                <div className='flex items-center justify-between bg-gray-100 px-2 py-3'>
                                    <p className='text-lg font-bold'>Total Premium</p>
                                    <p className='text-lg font-bold'>5,645</p>
                                </div>

                                <div className='w-full my-2'>
                                    <button className='border w-full py-2 rounded-lg bg-blue-500 text-white font-semibold text-lg hover:opacity-90'>Proceed to proposal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProceedToBuy
