import React from 'react'
import Banner from '../Banner'
import { RiAuctionFill } from 'react-icons/ri'
import { HiUserGroup } from 'react-icons/hi'
import { SiTarget } from 'react-icons/si'
import { MdInsights } from 'react-icons/md'
import { Link } from 'react-router-dom'

const Awareness = () => {
    return (
        <div className='w-full'>

            <div className='w-full h-[70vh] md:h-[70vh] bgColor text-white flex flex-col items-center justify-center'>
                <h1 className='text-3xl md:text-6xl text-center font-bold mt-16'>Strategic Brand Building</h1>
                <p className='w-[80%] md:text-lg text-center py-5'>
                    Elevate your brand with our platform's advanced targeting, delivering impactful ads, extending your reach. We offer transparent, real-time measurable results.
                </p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full flex flex-col items-center'>
                <div className='w-[95%]'>
                    <h2 className='text-center text-3xl md:text-6xl textColor font-bold pt-10 md:pt-20'>Powerful Awareness Campaign</h2>
                    <p className='text-base md:text-xl text-center pt-5 font-semibold textColor'>
                        Discover the BidBox Advantage
                    </p>
                    <p className='text-base md:text-lg text-center pt-2'>
                        Choose BidBox for pinpoint targeting, personalized ads, and superior results. BidBox helps you reach your audience and maximize campaign success.
                    </p>

                    <div className='w-full flex flex-wrap md:flex-nowrap justify-between mt-10'>
                        <div className='w-full mt-5 md:mt-0 flex flex-col items-center'>
                            <div className='w-40 h-40 bg-gradient-to-r from-purple-600 to-blue-600 border shadow-lg rounded-full flex items-center justify-center'>
                                <RiAuctionFill className='text-white text-[100px]' />
                            </div>
                            <h2 className='text-center pt-5 textColor'>Real-Time Bidding (RTB) Analytics</h2>
                        </div>
                        <div className='w-full mt-10 md:mt-0 flex flex-col items-center'>
                            <div className='w-40 h-40 bg-gradient-to-r from-purple-600 to-blue-600 border shadow-lg rounded-full flex items-center justify-center'>
                                <SiTarget className='text-white text-[100px]' />
                            </div>
                            <h2 className='text-center pt-5 textColor'>Diverse Targeting</h2>
                        </div>
                        <div className='w-full mt-10 md:mt-0 flex flex-col items-center'>
                            <div className='w-40 h-40 bg-gradient-to-r from-purple-600 to-blue-600 border shadow-lg rounded-full flex items-center justify-center'>
                                <HiUserGroup className='text-white text-[100px]' />
                            </div>
                            <h2 className='text-center pt-5 textColor'>Extensive Audience Reach</h2>
                        </div>
                        <div className='w-full mt-10 md:mt-0 flex flex-col items-center'>
                            <div className='w-40 h-40 bg-gradient-to-r from-purple-600 to-blue-600 border shadow-lg rounded-full flex items-center justify-center'>\
                                <MdInsights className='text-white text-[100px]' />
                            </div>
                            <h2 className='text-center pt-5 textColor'>Data-Driven Insights</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-col  items-center'>

                <div className='w-[95%] mb-10 md:my-24 md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3'>
                            Benchmarking the DSP Landscape
                        </h1>
                        <p className='text-base font-medium md:text-lg text-center md:text-start textColor'>
                            Intuitive Dashboard Excellence Experience the future of programmatic advertising at your fingertips.
                        </p>
                        <div className='mt-5 w-[90%]'>
                            <ul className='pl-5 text-gray-600'>
                                <li className='text-sm list-disc'>19% of advertisers are increasing their programmatic spend due to better ROI and performance.</li>
                                <li className='py-2 text-sm list-disc'>Global programmatic ad spend is expected to reach $558 million in 2023</li>
                                <li className='text-sm list-disc'>Programmatic advertising will account for 91% of total digital display ad spend in 2023</li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full md:w-[60%]'>
                        <img className='w-[100%] h-[100%] object-cover' src='/blogpage16.jpeg' alt='imagee' />
                    </div>
                </div>

            </div>

            <Banner />

        </div>
    )
}

export default Awareness
