import React from 'react'
import { BsFillHandIndexThumbFill, BsHandIndexThumb } from 'react-icons/bs'
import { FaHandPointDown } from 'react-icons/fa'
import CreditCardBox from './CreditCardBox'
import CardComponent from './CardComponent'

const CreditCard = () => {
    return (
        <>
            <div className='w-full'>
                {/* <div className='w-full h-screen flex justify-center'>
                    <div className='w-[90%] flex flex-wrap md:flex-nowrap md:justify-between'>
                        <div className='w-full flex items-center md:hidden'>
                            <img src='https://cardinsider.com/wp-content/uploads/2023/05/slider.png-1.webp' />
                        </div>

                        <div className='w-full flex justify-center md:justify-start md:items-center'>
                            <div className='w-[75%] flex flex-col item-center'>
                                <h1 className='font-extrabold text-center md:text-left text-3xl md:text-5xl'>Find The Right <span className='textColor'>Credit Card</span></h1>
                                <p className='md:text-xl text-center md:text-left font-medium py-5'>
                                    Compare India&apos;s best credit cards under one roof and apply for the one that fits your needs.
                                </p>
                                <a href='#compare-cards'>
                                    <button className='border flex items-center md:text-xl px-4 py-2 rounded-lg shadow-md bg-black text-white font-medium'>
                                        Compare your card now! <FaHandPointDown className='text-2xl pl-2 animate-pulse' />
                                    </button>
                                </a>
                            </div>
                        </div>

                        <div className='w-full md:flex items-center hidden'>
                            <img src='https://cardinsider.com/wp-content/uploads/2023/05/slider.png-1.webp' />
                        </div>
                    </div>
                </div> */}

                <div className='w-[90%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
                    <div className='w-fit flex flex-col gap-8 mt-5'>
                        <CardComponent cardName="ICICI Bank Coral" cardImage="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/8888b9755a9a43079ac5f272e21e4991.png" />
                        <CardComponent cardName="HDFC Bank Millenia" cardImage="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/2c930e2937da4a37b25314a6a6355a25.png" />
                        <CardComponent cardName="HDFC Bank Infinia Metal Edition" cardImage="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/0f6ccfd586884b3ba86c71ec1a0c5f45.png" />
                        <CardComponent cardName="HDFC Bank Regalia" cardImage="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/a371aaded5454a5ab44978e17e5c4f30.png" />
                        <CardComponent cardName="Standard Chartered Super Value Titanium" cardImage="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/174e1eed23064b44bdb2b02b1944ed4b.png" />
                        <CardComponent cardName="Citi Bank Cashback" cardImage="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/4ad3e083552948e1a181f22685520315.png" />
                    </div>

                    <div className='w-full relative'>
                        <div className='sticky overflow-hidden top-5 shadow-lg w-full h-[230px] rounded-lg bg-blue-500 border flex flex-col justify-between'>
                            <div className='w-[70%] mx-auto'>
                                <h2 className='text-center font-medium pt-5 text-lg'><span className='font-semibold text-white'>816 people</span> are on call with our advisors right now</h2>
                            </div>
                            <div className='w-full mx-auto'>
                                <div className='w-[70%] mx-auto'>
                                    <img src='https://static.pbcdn.in/health-cdn/images/insurer-logo/quotes-logos/advisors-logo.png' alt='imgg' />
                                </div>
                                <div className='bg-white'>
                                    <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='w-[70%] h-[200px] border shadow-lg hover:shadow-xl rounded-lg flex items-center'>
                    <div className='w-fit h-[180px] ml-2'>
                        <img className='w-[100%] h-[100%] object-cover' alt='' src="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/8888b9755a9a43079ac5f272e21e4991.png" />
                    </div>

                    <div className='h-[180px] w-[330px] flex flex-col justify-between ml-3'>
                        <div className=''>
                            <h1 className='font-bold'>ICICI Bank</h1>

                            <div class="flex items-center">
                                <svg class="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                <svg class="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                <svg class="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                <svg class="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                <svg class="w-4 h-4 text-gray-300 mr-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                            </div>

                            <p className='text-gray-400 mt-2 text-sm'>1st year fee: <span className='text-black'>0</span></p>

                            <div className='flex flex-wrap gap-2 mt-2 box-border'>
                                <div className='border shadow-md'>
                                    <span className='text-xs text-gray-600 p-2'>No Room Rent Limit</span>
                                </div>
                                <div className='border shadow-md'>
                                    <span className='text-xs text-gray-600 p-2'>15 Lakh No Claim Bonus</span>
                                </div>
                                <div className='border shadow-md'>
                                    <span className='text-xs text-gray-600 p-2'>Unlimited Restoration of Cover</span>
                                </div>
                                <div className=''>
                                    <span className='text-xs font-semibold cursor-pointer text-blue-500 p-2'>View all features</span>
                                </div>
                            </div>

                        </div>

                        <div className='flex gap-2'>
                            <input type='checkbox' />
                            <p className='text-sm font-semibold text-gray-500'>Add to compare</p>
                        </div>
                    </div>
                </div> */}

                {/* <div id='compare-cards' className='w-full md:h-screen flex flex-wrap justify-center'>
                    <div className='md:w-[40%] shadow-xl'>
                        <img className='w-[100%] h-[100%] object-cover' src='creditCard.gif' alt='credit-card' />
                    </div>

                    <div className='md:w-[60%] flex flex-col p-5'>

                        <h1 className='text-3xl text-center md:text-left mt-5 md:mt-0 md:text-5xl font-bold textColor'>Card Comparison</h1>
                        <p className='py-5 px-5 md:px-0 font-medium text-center md:text-left'>Compare up to 3 Credit Cards of your choice at once with the right data for a wise decision.</p>
                        <h2 className='text-xl md:text-3xl text-center md:text-left font-bold'>Full Details Side by Side</h2>
                        <p className='py-5 px-5 md:px-0 font-medium text-center md:text-left md:w-[60%]'>Select&apos;s Card Comparison tool combines advanced tech with credible data to fuel your choice of a card that best fits your needs. Use it to find a card that&apos;ll save you more, earn you more and ease your financial journey the most.</p>

                        <div className='w-full flex flex-wrap md:flex-nowrap justify-center md:justify-start gap-3'>
                            <div className='w-[90%]'>
                                <h3 className='mb-2 font-bold textColor'>Card 1</h3>

                                <select name="card" id="card" className='px-2 py-2 bgColor text-white border-2 border-[#00308F] w-full rounded-lg'>
                                    <option value="volvo">Pick a Card</option>
                                    <option value="volvo">HDFC Bank</option>
                                    <option value="saab">ICICI Bank</option>
                                    <option value="mercedes">American Express</option>
                                    <option value="audi">SBI Bank</option>
                                </select>
                            </div>

                            <div className='w-[90%]'>
                                <h3 className='mb-2 font-bold textColor'>Card 2</h3>

                                <select name="card" id="card" className='px-2 bgColor text-white py-2 border-2 border-[#00308F] w-full rounded-lg'>
                                    <option value="Pick a Card">Pick a Card</option>
                                    <option value="HDFC Bank">HDFC Bank</option>
                                    <option value="ICICI Bank">ICICI Bank</option>
                                    <option value="American Express">American Express</option>
                                    <option value="SBI Bank">SBI Bank</option>
                                </select>
                            </div>

                            <div className='w-[90%]'>
                                <h3 className='mb-2 font-bold textColor'>Card 3</h3>

                                <select name="card" id="card" className='px-2 bgColor text-white py-2 border-2 border-[#00308F] w-full rounded-lg'>
                                    <option value="volvo">Pick a Card</option>
                                    <option value="volvo">HDFC Bank</option>
                                    <option value="saab">ICICI Bank</option>
                                    <option value="mercedes">American Express</option>
                                    <option value="audi">SBI Bank</option>
                                </select>
                            </div>

                            <div className='w-[90%]'>
                                <h3 className='mb-2 font-bold textColor'>Card 4</h3>

                                <select name="card" id="card" className='px-2 bgColor text-white py-2 border-2 border-[#00308F] w-full rounded-lg'>
                                    <option value="volvo">Pick a Card</option>
                                    <option value="volvo">HDFC Bank</option>
                                    <option value="saab">ICICI Bank</option>
                                    <option value="mercedes">American Express</option>
                                    <option value="audi">SBI Bank</option>
                                </select>
                            </div>

                            <div className='w-[90%]'>
                                <h3 className='mb-2 font-bold textColor'>Card 5</h3>

                                <select name="card" id="card" className='px-2 bgColor text-white py-2 border-2 border-[#00308F] w-full rounded-lg'>
                                    <option value="volvo">Pick a Card</option>
                                    <option value="volvo">HDFC Bank</option>
                                    <option value="saab">ICICI Bank</option>
                                    <option value="mercedes">American Express</option>
                                    <option value="audi">SBI Bank</option>
                                </select>
                            </div>
                        </div>

                        <div className='w-full flex flex-wrap md:flex-nowrap justify-center md:justify-start items-center gap-3 mt-10'>
                            <button className='py-3 flex items-center justify-center w-[300px] bg-black shadow-lg text-white font-bold text-lg border rounded-xl'>
                                Start Comparing Now <BsFillHandIndexThumbFill className='ml-2' />
                            </button>
                            <a href='#most-compared-card'>
                                <p className='font-medium text-blue-500 text-lg'>or see most Compared Cards</p>
                            </a>
                        </div>

                    </div>
                </div> */}

                <div id='most-compared-card' className='w-full flex justify-center'>
                    <div className='w-[90%]'>
                        <div className='mt-16'>
                            <h2 className='font-extrabold text-center text-2xl md:text-5xl'>Most Compared Credit Cards</h2>
                            <p className='py-5 text-lg md:text-xl text-center'>These are some comparisons to start with. Choose from the most compared credit cards by Finology Select users.</p>
                        </div>
                        <div className='flex flex-wrap md:flex-nowrap gap-10 justify-between md:my-10'>
                            <CreditCardBox cardName1="HDFC Bank Millenia" cardName2="ICICI Bank Coral" cardImage1="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/8888b9755a9a43079ac5f272e21e4991.png" cardImage2="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/2c930e2937da4a37b25314a6a6355a25.png" />
                            <CreditCardBox cardName1="HDFC Bank Infinia Metal Edition" cardName2="HDFC Bank Regalia" cardImage1="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/0f6ccfd586884b3ba86c71ec1a0c5f45.png" cardImage2="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/a371aaded5454a5ab44978e17e5c4f30.png" />
                            <CreditCardBox cardName1="Standard Chartered Super Value Titanium" cardName2="Citi Bank Cashback" cardImage1="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/174e1eed23064b44bdb2b02b1944ed4b.png" cardImage2="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/4ad3e083552948e1a181f22685520315.png" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreditCard
