'use client';
import { Button, Modal } from 'flowbite-react';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import CardAccordion from './CardAccordion';

const CardModal = () => {
    const [openModal, setOpenModal] = useState('');

    return (
        <>
            {/* <Button onClick={() => setOpenModal('default')}>Toggle modal</Button> */}
            <a href="#all-feature">
                <span onClick={() => setOpenModal('default')} className='text-xs font-semibold cursor-pointer text-blue-500 p-2'>View all features</span>
            </a>

            <Modal id='all-feature' show={openModal === 'default'} onClose={() => setOpenModal('')}>
                <Modal.Header>Features</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <h2 className='text-2xl font-bold'>Coverage</h2>
                        <CardAccordion/>
                        <h2 className='text-2xl font-bold'>Waiting periods</h2>
                        <CardAccordion/>
                        <h2 className='text-2xl font-bold'>Additional Features</h2>
                        <CardAccordion/>
                        <h2 className='text-2xl font-bold'>Value Added Services</h2>
                        <CardAccordion/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setOpenModal('')}>I accept</Button>
                    <Button color="gray" onClick={() => setOpenModal('')}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CardModal
