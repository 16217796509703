import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/landingPage/Home";
import Supply from "./components/supplySideCampaign/Supply";
import Download from "./components/supplySideCampaign/Download";
import Blog from "./components/blog/Blog";
import CreativeServices from "./components/creativeServices/CreativeServices";
import MobileAdd from "./components/mobileAdd/MobileAdd";
import DriveStore from "./components/driveStore/DriveStore";
import SuccessStory from "./components/successStory/SuccessStory";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import CreditCard from "./components/creditCard/CreditCard";
import Omnichannel from "./components/contact/omnichannel/Omnichannel";
import PersonalLoan from "./components/personalLoan/PersonalLoan";
import BlogHarshita from "./components/blogHarshita/BlogHarshita";
import Report from "./components/report/Report";
import ProceedToBuy from "./components/creditCard/ProceedToBuy";
import CardDetails from "./components/creditCard/CardDetails";
import Aviation from "./components/careers/Aviation";
import Gaming from "./components/careers/Gaming";
import Automobile from "./components/careers/Automobile";
import Entertainment from "./components/careers/Entertainment";
import Fmcg from "./components/careers/Fmcg";
import Bfsi from "./components/careers/Bfsi";
import Display from "./components/channelwise/Display";
import InApp from "./components/channelwise/InApp";
import Ctv from "./components/channelwise/Ctv";
import Video from "./components/channelwise/Video";
import TargetingOptions from "./components/solutions/TargetingOptions";
import Awareness from "./components/campaignwise/Awareness";
import Retargeting from "./components/campaignwise/Retargeting";
import CaseStudy from "./components/careers/CaseStudy";
import ProgrammaticAdvertising from "./components/blog/ProgrammaticAdvertising";
import TargetedAdvertising from "./components/blog/TargetedAdvertising";
import DataDrivenAdvertising from "./components/blog/DataDrivenAdvertising";
import CrossChannelAdvertising from "./components/blog/CrossChannelAdvertising";
import FutureOfDigitalAdvertising from "./components/blog/FutureOfDigitalAdvertising";
import VideoAdvertising from "./components/blog/VideoAdvertising";
import Cookie_Policy from "./components/cookiePolicy/Cookie_Policy";
import CaseStudyContainer from "./components/careers/CaseStudyContainer";
import PushAds from "./components/channelwise/PushAds";
import NativeAds from "./components/channelwise/NativeAds"
import InterstitialAds from "./components/channelwise/Interstitial-Ads"
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import TermsAndConditions from "./components/privacy/TermsAndConditions";
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />
      },
      {
        path: "/push-ads",
        element: <PushAds/>
      },
      {
        path: "/native-ads",
        element: <NativeAds />

      },
      {
        path: "/interstitial-ads",
        element: <InterstitialAds />
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/supply",
        element: <Supply />,
      },
      {
        path: "/download",
        element: <Download />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/Programmatic-advertising",
        element: <ProgrammaticAdvertising />,
      },
      {
        path: "/Targeted-advertising",
        element: <TargetedAdvertising />,
      },
      {
        path: "/Data-driven-advertising",
        element: <DataDrivenAdvertising />,
      },
      {
        path: "/cross-channel-advertising",
        element: <CrossChannelAdvertising />,
      },
      {
        path: "/future-of-digital-advertising",
        element: <FutureOfDigitalAdvertising />,
      },
      {
        path: "/video-advertising",
        element: <VideoAdvertising />,
      },
      {
        path: "/creativeServices",
        element: <CreativeServices />,
      },
      {
        path: "/mobileAdd",
        element: <MobileAdd />,
      },
      {
        path: "/omnichannel",
        element: <Omnichannel />,
      },
      {
        path: "/driveStore",
        element: <DriveStore />,
      },
      // {
      //   path: "/success",
      //   element: <SuccessStory />,
      // },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/credit-card",
        element: <CreditCard />,
      },
      {
        path: "/personal-loan",
        element: <PersonalLoan />,
      },
      {
        path: "/blog_harshita",
        element: <BlogHarshita />,
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/proceed-to-buy",
        element: <ProceedToBuy />,
      },
      {
        path: "/card-details",
        element: <CardDetails />,
      },
      {
        path: "/awareness",
        element: <Awareness />,
      },
      {
        path: "/re-targeting",
        element: <Retargeting />,
      },
      {
        path: "/display",
        element: <Display />,
      },
      {
        path: "/in-app",
        element: <InApp />,
      },
      {
        path: "/ctv",
        element: <Ctv />,
      },
      {
        path: "/video",
        element: <Video />,
      },
      {
        path: "/targeting-options",
        element: <TargetingOptions />,
      },
      {
        path: "/case-study",
        element: <CaseStudyContainer />,
      },
      {
        path: "/bfsi",
        element: <Bfsi />,
      },
      {
        path: "/fmcg",
        element: <Fmcg />,
      },
      {
        path: "/entertainment",
        element: <Entertainment />,
      },
      {
        path: "/automobile",
        element: <Automobile />,
      },
      {
        path: "/gaming",
        element: <Gaming />,
      },
      {
        path: "/aviation",
        element: <Aviation />,
      },
      {
        path: "/cookie-policy",
        element: <Cookie_Policy />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
