import React, { useState } from 'react';

const CaseStudyCard = ({ image, heading, content, vtr, impression, content1, content2 }) => {
    const [readMode, setReadMode] = useState(false);

    const handleClick = () => {
        setReadMode(!readMode);
    };

    return (
        <div className={`w-[380px]  shadow-xl hover:text-[#00308F] hover:shadow-1xl ${readMode ? 'h-auto bg-white' : 'h-[600px] bg-white'}`}>
            <div className='w-full h-[200px] overflow-hidden'>
                <img className='w-[100%] h-[100%] object-fill hover:scale-110 duration-200' src={image} alt='photo' />
            </div>
            <div className='py-2 px-4'>
                <h2 className='font-bold py-2 text-2xl'>{heading}</h2>
                <div className={`overflow-hidden ${readMode ? 'h-auto' : 'h-[180px]'}`}>
                    <p className='text-sm pt-2'>
                        {content}
                        {readMode && (
                            <>
                                <p className='text-sm pt-2'>{content1}</p>
                                <p className='text-sm pt-2'>{content2}</p>
                            </>
                        )}
                    </p>
                </div>
                <div className='flex justify-between mt-5 textColor'>
                    <div>{impression}</div>
                    <div>{vtr}</div>
                </div>

                <p onClick={handleClick} className='py-5 underline cursor-pointer text-sm text-blue-500'>
                    {readMode ? 'Show less' : 'Read more'}
                </p>
            </div>
        </div>
    );
};

export default CaseStudyCard;






// import React, { useState } from 'react'

// const CaseStudyCard = ({ image, heading, content, vtr, impression, content1, content2 }) => {
//     const [readMode, setReadMode] = useState(false)

//     return (
//         <div className={`w-[330px]  ${readMode ? 'h-fit' : ''} shadow-xl hover:text-[#00308F] hover:shadow-2xl`}>
//             <div className='w-full h-[200px] overflow-hidden'>
//                 <img className='w-[100%] h-[100%] object-fill hover:scale-110 duration-200' src={image} alt='photo' />
//             </div>
//             <div className='py-2 px-4 '>
//                 <div className='overflow-hidden'>
//                     <h2 className='font-bold py-2 text-2xl'>{heading}</h2>
//                     <p className=' text-sm pt-2'>
//                         {content}
//                     </p>
//                     {
//                         readMode &&
//                         <>
//                             <p className=' text-sm pt-2'>
//                                 {content1}
//                             </p>
//                             <p className=' text-sm pt-2'>
//                                 {content2}
//                             </p>
//                         </>
//                     }
//                     <div className='flex justify-between mt-5 textColor'>
//                         <div>{impression}</div>
//                         <div>{vtr}</div>
//                     </div>
//                 </div>

//                 <p onClick={() => setReadMode(prev => !prev)} className='py-5 underline cursor-pointer text-sm text-blue-500'>{readMode ? 'Show less' : 'Read more'}</p>

//             </div>
//         </div>
//     )
// }

// export default CaseStudyCard
