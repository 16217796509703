import React from 'react'
import Banner from '../Banner'
import { TbTargetArrow } from 'react-icons/tb'
import { GiBoltEye } from "react-icons/gi";
import { MdPriceCheck } from "react-icons/md";
import { HiCursorClick } from "react-icons/hi";
import { SiPagespeedinsights } from "react-icons/si";
import { MdOutlineVideoSettings } from "react-icons/md";
import { Link } from 'react-router-dom';

const Video = () => {

    return (
        <div className='w-full'>

            <div className='w-full h-[90vh] md:h-[70vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl md:text-6xl text-center font-bold mt-16'>Video Advertising with DSPs</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>
                    Video advertising through Demand-Side Platforms (DSPs) offers a powerful way to reach your target audience with engaging visual content. With the ability to precisely target viewers based on their demographics, interests, and online behavior, DSPs enable advertisers to deliver tailored video ads across a variety of digital channels.
                </p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full pt-10 flex flex-col items-center'>

                <div className='w-[95%] md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                            Unlock the Power of Video Advertising Through DSPs
                        </h1>
                        <div className='w-full md:hidden md:w-[50%]'>
                            <img className='w-[100%] h-[100%] object-cover' src='/VideoAds.jpg' alt='imagee' />
                        </div>
                        <p className='text-base md:text-lg pt-5 text-center md:text-start text-black'>
                            Are you ready to revolutionize your digital advertising strategy? Explore the incredible world of video advertising with the help of Demand-Side Platforms (DSPs). Discover how this dynamic approach can elevate your online presence and engage your target audience effectively.
                        </p>
                        <p className='text-base md:text-lg pb-5 text-center md:text-start text-black pt-2'>
                            Explore the potential of video advertising through DSPs and unlock new horizons for your brand. Connect with us today to embark on a journey towards higher engagement, enhanced brand visibility, and superior advertising ROI.
                        </p>
                        <div className='mt-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>
                    <div className='w-full hidden md:block md:w-[50%]'>
                        <img className='w-[100%] h-[100%] object-cover' src='/VideoAds.jpg' alt='imagee' />
                    </div>
                </div>

            </div>

            <div className='w-full md:h-[95vh] bg-gradient-to-r from-purple-600 to-blue-600 py-10 md:pt-10 my-10 flex flex-col justify-center items-center'>
                <div className='w-[95%] text-white md:py-20 flex flex-col items-center'>
                    <h2 className='text-center text-3xl md:text-6xl font-bold'>Diverse Display Ad Formats</h2>
                    <p className='md:w-[80%] py-5 md:py-10 text-gray-100 font-medium text-xl text-center'>Amplify audience engagement with our team&apos;s professionally designed dynamic and interactive ads or select from our cutting-edge ready-to-use templates.</p>
                    <div className='w-full flex justify-center flex-wrap gap-4 md:gap-8'>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Display Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Video Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Native Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Dynamic Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Rich Media Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Roadblock Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interstitial Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interactive Ad</div>
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col my-20 justify-center'>
                    <h1 className='text-center text-3xl md:text-6xl textColor font-bold'>Why Video Advertising?</h1>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <GiBoltEye className='text-8xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Visual Impact</h1>
                            <p className='textColor font-medium text-center'>
                                Video ads captivate viewers with compelling visuals, conveying your message more vividly than traditional text or images.
                            </p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <HiCursorClick className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>High Engagement</h1>
                            <p className='textColor font-medium text-center'>Videos create emotional connections and significantly boost user engagement, making your brand more memorable.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <MdOutlineVideoSettings className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Versatility</h1>
                            <p className='textColor font-medium text-center'>
                            Video advertising provides flexibility with pre-roll, mid-roll, post-roll, and native formats, aligning with campaign goals.
                            </p>
                        </div>
                    </div>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-22 h-22'>
                                <TbTargetArrow className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Precise Targeting</h1>
                            <p className='textColor font-medium text-center'>DSPs enable pinpoint accuracy in reaching your ideal audience, ensuring your message resonates with the right viewers.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <MdPriceCheck className='text-8xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Cost-Efficiency</h1>
                            <p className='textColor font-medium text-center'>Maximize your ad spend by delivering ads to the most relevant users, reducing wasted impressions and boosting ROI.</p>
                        </div>
                        <div className='w-full border h-[250px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-22 h-22'>
                                <SiPagespeedinsights className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Rich Data Insights</h1>
                            <p className='textColor font-medium text-center'>Gain valuable insights into viewer behavior and preferences, allowing you to refine your ad strategy for better results.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Banner />
        </div>
    )
}

export default Video
