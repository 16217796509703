import React, { useState } from 'react';
// import CaseStudyCard from './CaseStudyCard';

const CaseStudyContainer = () => {
  const [readMode, setReadMode] = useState(false);

  const toggleReadMode = () => {
    setReadMode((prev) => !prev);
  };

  return (
    <div className='mt-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between'>
      {/* Other components or content */}
      <CaseStudyContainer
        image="https://picsum.photos/id/1005/800/450"
        heading="De Beers' Diwali Radiance"
        impression="Impressions 3 Million+"
        vtr="VTR 97% +"
        content="In a strategic endeavor to capture the essence of Diwali, we partnered with the prestigious De Beers Forevermark Jewelry brand for a CTV campaign..."
        content1="The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views."
        content2="This case study illuminates our capability to deliver outstanding results for our partners, harnessing the allure of CTV during festive seasons to maximize brand engagement."
        readMode={readMode}
        toggleReadMode={toggleReadMode}
      />
      <CaseStudyContainer
        image="https://picsum.photos/id/1005/800/450"
        heading="De Beers' Diwali Radiance"
        impression="Impressions 3 Million+"
        vtr="VTR 97% +"
        content="In a strategic endeavor to capture the essence of Diwali, we partnered with the prestigious De Beers Forevermark Jewelry brand for a CTV campaign..."
        content1="The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views."
        content2="This case study illuminates our capability to deliver outstanding results for our partners, harnessing the allure of CTV during festive seasons to maximize brand engagement."
        readMode={readMode}
        toggleReadMode={toggleReadMode}
      />
      <CaseStudyContainer
        image="https://picsum.photos/id/1005/800/450"
        heading="De Beers' Diwali Radiance"
        impression="Impressions 3 Million+"
        vtr="VTR 97% +"
        content="In a strategic endeavor to capture the essence of Diwali, we partnered with the prestigious De Beers Forevermark Jewelry brand for a CTV campaign..."
        content1="The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views."
        content2="This case study illuminates our capability to deliver outstanding results for our partners, harnessing the allure of CTV during festive seasons to maximize brand engagement."
        readMode={readMode}
        toggleReadMode={toggleReadMode}
      />
      {/* Repeat the above for other instances of CaseStudyCard */}
    </div>
  );
};

export default CaseStudyContainer;
