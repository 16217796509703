import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaHandPointRight } from 'react-icons/fa'
import { RxCross2 } from 'react-icons/rx'
import Footer from '../Footer'

const Report = () => {
    const [showSolution, setShowSolution] = useState(false);
    const [showResources, setShowResources] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const [isNav, setIsNav] = useState(false);

    return (
        <>
            <div className='w-full'>

                <div className='video-container w-full h-screen relative bg-black flex flex-col items-center'>
                    <video autoPlay muted loop playsInline preload="auto" className='opacity-50 bg-black'>
                        <source src="/success.mp4" type="video/mp4" />
                    </video>

                    {
                        isNav && <>
                            <div className='fixed top-0 flex justify-center z-50 w-full h-screen bg-[#FBF8BE]'>
                                <div className='w-[95%] mt-5 flex justify-between'>
                                    <div>Logo</div>
                                    <div className='flex'>
                                        <RxCross2 className='text-2xl cursor-pointer' onClick={() => setIsNav((prev) => !prev)} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <div className='w-[95%] caption mt-5 flex justify-between'>
                        <Link to="/">
                        <div className='text-[#FBF8BE] z-20'>Logo</div>
                        </Link>

                        <ul className='hidden z-50 text-[#FBF8BE] font-semibold md:flex gap-10 md:items-center'>
                            <li onMouseOver={() => setShowSolution(true)} onMouseLeave={() => setShowSolution(false)} className='relative cursor-pointer'>
                                SOLUTIONS
                                {
                                    showSolution && <>
                                        <div onMouseOver={() => setShowSolution(true)} className='absolute z-50 bottom-0 translate-y-[100%] bg-[#FBF8BE] rounded-lg w-fit pb-5 shadow-lg py-3 border'>
                                            <Link to="/supply">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Connected TV with Household Sync</p>
                                            </Link>
                                            <Link to="/creativeServices">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Dooh with Audience Sync</p>
                                            </Link>
                                            <Link to="/mobileAdd">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Impactfull Mobile Ads</p>
                                            </Link>
                                            <Link to="/omnichannel">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Scalable OmniChannel Targeting</p>
                                            </Link>
                                            <Link to="/driveStore">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Drive to Store with incrementality</p>
                                            </Link>
                                        </div>
                                    </>
                                }
                            </li>

                            <li onMouseOver={() => setShowResources(true)} onMouseLeave={() => setShowResources(false)} className='relative cursor-pointer'>
                                RESOURCES
                                {
                                    showResources && <>
                                        <div onMouseOver={() => setShowResources(true)} className='absolute z-50 bottom-0 translate-y-[100%] bg-[#FBF8BE] rounded-lg w-fit shadow-lg py-3 border'>
                                            <Link to="/blog">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Blog</p>
                                            </Link>
                                            <Link to="/success">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Success Stories</p>
                                            </Link>
                                            <Link to="/report">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Reports</p>
                                            </Link>
                                        </div>
                                    </>
                                }
                            </li>

                            <li onMouseOver={() => setShowAbout(true)} onMouseLeave={() => setShowAbout(false)} className='relative cursor-pointer'>
                                ABOUT US
                                {
                                    showAbout && <>
                                        <div onMouseOver={() => setShowAbout(true)} className='absolute z-50 bottom-0 right-[50%] translate-x-[50%] translate-y-[100%] bg-[#FBF8BE] rounded-lg w-fit h-fit border pb-5 shadow-lg py-3'>
                                            {/* <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Our Culture</p>
                                            <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Team</p>
                                            <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Career</p> */}
                                            <Link to="/about">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> About Us</p>
                                            </Link>
                                            <Link to="/contact">
                                                <p className='whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-[#FBF8BE] px-5 hover:shadow-md flex items-center'><FaHandPointRight className='mr-1.5' /> Contact Us</p>
                                            </Link>
                                        </div>
                                    </>
                                }
                            </li>

                            <div>
                                <button className='border border-[#FBF8BE] px-4 shadow-md font-semibold py-1 bg-[#FBF8BE] hover:opacity-90 textColor rounded-lg mr-3'>LOGIN</button>
                                <button className='border border-[#FBF8BE] px-4 shadow-md font-semibold py-1 hover:bg-[#FBF8BE] hover:textColor rounded-lg'>SIGN UP</button>
                            </div>
                        </ul>

                        <div className='flex md:hidden'>
                            <GiHamburgerMenu onClick={() => setIsNav((prev) => !prev)} className='text-[#FBF8BE] text-2xl cursor-pointer' />
                        </div>
                    </div>

                    <div className='w-[95%] mt-40 flex items-center justify-center'>
                        <div className='md:w-[50%] caption flex flex-col items-center md:flex-none md:items-start'>
                            <h1 className='text-[#FBF8BE] text-center p-5 text-4xl md:text-6xl font-bold'>Market Analysis and Trends in the Technology Sector</h1>
                            {/* <p className='text-[#FBF8BE] text-center md:text-left text-xl md:text-2xl font-semibold pt-5'>Unified Programmatic Platform</p> */}
                            {/* <button className='mt-10 w-40 py-2 rounded-xl bg-[#FBF8BE] textColor text-lg font-bold shadow-lg hover:opacity-90'>Get in Touch</button> */}
                        </div>
                        {/* <div className=''>
                        </div> */}
                    </div>

                </div>

                <div className='w-full py-10 flex flex-col items-center'>
                    <div className='w-[95%]'>
                        <h1 className='textColor text-center p-5 text-4xl md:text-6xl font-bold'>Analysis and Interpretation</h1>
                        <p className='text-center text-xl md:text-2xl font-semibold pt-5'> In this section, the data and information are analyzed, and their implications are discussed. It's where readers gain insights into the significance of the findings.</p>
                    </div>
                    <div className='w-[95%] flex flex-col my-10 justify-center'>

                        <div className='w-full flex gap-10 mt-10'>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366415d125b7db6ad7f46ed_Transparency%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664428cb0f68cf2e5b1fe8_Target%20audience%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664433943c1ac401d20c78_Placement%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                        </div>

                        <div className='w-full flex gap-10 mt-10'>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366446818df79d2bd0b6825_Audience%20icon1.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664477bd2623d65822bf77_Mobile%20verification%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366447fcaac03448cecb3f6_Creatives%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Footer/>
        </>
    )
}

export default Report
