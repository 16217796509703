import React from 'react'
import Banner from '../Banner'
import { TbChartInfographic, TbTargetArrow } from 'react-icons/tb'
import { MdCampaign, MdGroups2 } from 'react-icons/md'
import { DiHtml53DEffects } from 'react-icons/di'
import { PiListMagnifyingGlass } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import { FaEye } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";
import { FaPersonCircleCheck } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";

const Display = () => {
    return (
        <div className='w-full'>

            <div className='w-full h-[70vh] md:h-[60vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold mt-16'>Native Ads</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>Native Advertising through DSP seamlessly integrates tailored ads into content, ensuring precise audience targeting and scalability. It fosters authenticity, builds trust, and enhances engagement, optimizing ROI effectively.
</p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full pt-10 flex flex-col items-center'>

                <div className='w-[95%] md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                        Maximizing Engagement through Native Ads
                        </h1>
                        <div className='w-full md:hidden md:w-[50%] my-5'>
                            <img className='w-[100%] h-[100%] object-cover' src='/native1.jpeg' alt='imagee' />
                        </div>
                        <p className='text-base md:text-lg text-center md:text-start text-black'>
                        Native Advertising through DSPs seamlessly incorporates ads into content, aligning with the platform's aesthetics. DSPs enable precise targeting using demographics, interests, and behaviors, enhancing engagement and conversions.
                        </p>
                        <p className='text-base md:text-lg text-center md:text-start text-black pt-2'>
                        Ads seamlessly blend into user experiences, fostering trust and authenticity. Data analytics optimize campaigns for maximum ROI.

                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>
                    <div className='w-full hidden md:block md:w-[50%]'>
                        <img className='w-[100%] h-[100%] object-cover' src='/native1.jpeg' alt='imagee' />
                    </div>
                </div>

                {/* <div className='w-[95%] mt-10 md:flex md:justify-between]'>
                    <div className='md:w-[55%] hidden md:flex'>
                        <img className='w-[100%] h-[100%] object-cover' src='/Display ads 2.jpg' alt='image2' />
                    </div>

                    <div className='md:w-[40%] px-5 flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                            The Growth of Display Ads
                        </h1>

                        <div className='md:w-[55%] md:hidden my-5'>
                            <img className='w-[100%] h-[100%] object-cover' src='/Display ads 2.jpg' alt='image2' />
                        </div>

                        <p className='text-base md:text-lg text-center md:text-start text-black'>
                            Despite the growing popularity of other digital marketing tools, display ads are still important for an institution's marketing strategy and gaining a competitive advantage. Keeping up with the trends is fine, but not losing sight of tried and tested marketing techniques such as display ads is crucial.
                        </p>
                        <div className='my-5'>
                            <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                        </div>
                    </div> */}

                {/* </div> */}

            </div>

            <div className='w-full md:h-[95vh] bg-gradient-to-r from-purple-600 to-blue-600 py-10 md:pt-10 my-10 flex flex-col justify-center items-center'>
                <div className='w-[95%] text-white md:py-20 flex flex-col items-center'>
                    <h2 className='text-center text-3xl md:text-6xl font-bold'>Diverse Display Ad Formats</h2>
                    <p className='w-[80%] py-5 md:py-10 text-gray-100 font-medium text-xl text-center'>Amplify audience engagement with our team&apos;s professionally designed dynamic and interactive ads or select from our cutting-edge ready-to-use templates.</p>
                    <div className='w-full flex justify-center flex-wrap gap-4 md:gap-8'>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Display Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Video Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Native Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Dynamic Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Rich Media Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Roadblock Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interstitial Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interactive Ad</div>
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col my-20 justify-center'>
                    <h1 className='text-center text-3xl md:text-6xl textColor font-bold'>Why Native Ads?</h1>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366415d125b7db6ad7f46ed_Transparency%20icon.svg' /> */}
                                <HiCursorClick  className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Enhanced Engagement</h1>
                            <p className='textColor font-medium text-center'>
                            Native ads seamlessly blend into content, capturing attention and encouraging interaction with the audience.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664428cb0f68cf2e5b1fe8_Target%20audience%20icon.svg' /> */}
                                <FaPersonCircleCheck className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Improved Trust</h1>
                            <p className='textColor font-medium text-center'>
                            Authentic integration fosters credibility, building trust with consumers and strengthening brand reputation.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664433943c1ac401d20c78_Placement%20icon.svg' /> */}
                                <MdGroups2 className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Targeted Reach</h1>
                            <p className='textColor font-medium text-center'>
                            Precise audience targeting based on demographics, interests, and behaviors ensures relevance and maximizes campaign effectiveness.
                            </p>
                        </div>
                    </div>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366446818df79d2bd0b6825_Audience%20icon1.svg' /> */}
                                <MdCampaign className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Increased Brand Awareness</h1>
                            <p className='textColor font-medium text-center'>
                            Native ads amplify brand visibility by integrating naturally into the user experience, expanding reach and recognition.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664477bd2623d65822bf77_Mobile%20verification%20icon.svg' /> */}
                                <TbChartInfographic className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Higher Conversion Rates</h1>
                            <p className='textColor font-medium text-center'>
                            Contextually relevant content drives user action, leading to increased conversions and improved return on investment.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <FaDollarSign className='text-[80px] textColor' />
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366447fcaac03448cecb3f6_Creatives%20icon.svg' /> */}
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Cost-Effectiveness</h1>
                            <p className='textColor font-medium text-center'>
                            Efficient targeting reduces wasted ad spend, optimizing budget allocation and delivering better overall campaign performance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Banner />
        </div>
    )
}

export default Display
