import React from 'react'
import Banner from '../Banner'
import CaseStudyCard from './CaseStudyCard'

const Aviation = () => {
    return (
        <div className='w-full'>
            <div className='w-full h-[36vh] bgColor text-white flex justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold  text-center mt-14'>Consumer Electronics</h1>
            </div>

            <div className='w-full overflow-hidden'>

                <div className='w-full bg-white flex flex-col items-center'>
                    <div className='w-[95%] my-10'>
                        <h1 className='text-3xl md:text-5xl font-bold text-center'>Explore Diverse Case Studies</h1>
                        <p className='text-center py-5 text-xl font-medium'>Dive into success stories spanning various industries and verticals to see how we deliver value.</p>

                        <div className='my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between'>
                            <CaseStudyCard
                                image="/Nokia.png"
                                heading="Unlocking Hyperlocal Potential with Nokia"
                                impression='Website Visits - 200k+'
                                vtr='Walk Ins -  86%↑ '
                                content="In a strategic campaign for Nokia, we harnessed the power of hyperlocal targeting through the deployment of targeted Display Banners and rich media creatives, resulting in outstanding outcomes. We achieved over 200,000 website visits, accompanied by an impressive CTR rate of 0.95%."
                                content1="The campaign's engagement metrics underscore the precision of our hyperlocal targeting, showcasing an average session duration of 2.5 minutes and a remarkable bounce rate of less than 30%. This highlights our ability to effectively reach and engage a highly relevant local audience, contributing to Nokia's success."
                                content2="The impact also extended to Nokia's physical stores, where we observed a substantial average increase of 86% in walk-ins. Some stores even experienced remarkable month-over-month growth, soaring to as high as 316% during the campaign."
                            />
                            <CaseStudyCard
                                image="OnePlusLogo.png"
                                heading="OnePlus Excels in Regional Markets"
                                impression='Impressions - 5.2 Million+'
                                vtr='CTR - 0.85%'
                                content="In the fiercely competitive market, OnePlus sought to fortify their brand presence and enhance visibility for their established OnePlus 7 model, particularly in South India. Our strategic collaboration was designed to address this regional focus. "
                                content1="Utilizing a combination of language-based and geo-specific targeting, we achieved precision, resulting in 5.2 million impressions and an impressive CTR of 0.85. This case study underscores our innovative approach to elevate brand recognition and thrust the OnePlus 7 into the limelight in Southern India."
                                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
                            />
                            <CaseStudyCard
                                image="/samsunglogo.jpg"
                                heading="Samsung's Resounding Market Victory"
                                impression='Impressions - 3.52 Cr+'
                                vtr='Clicks - 195K+'
                                content="In our Indonesian market strategy, we leveraged display and native ads to showcase Samsung A05's features, ensuring optimal engagement through captivating visuals and strategic placements across various online platforms."
                                content1="The widespread and impactful campaign, executed through a sophisticated DSP, reinforced the Samsung A05 as a must-have device in the Indonesian market. The campaign generated 32+ million impressions with a CTR of 195k+ clicks, showcasing its resonance and effectiveness."
                                // content2="The campaign generated 32+ million impressions with a CTR of 280k+ clicks, showcasing its resonance and effectiveness."
                            />

                        </div>
                    </div>

                </div>

            </div>

            <Banner />
        </div>
    )
}

export default Aviation
