import React from 'react'
import Banner from '../Banner'
import { GoGraph } from 'react-icons/go'
import { HiUsers } from 'react-icons/hi'
import { AiFillBuild, AiOutlineAreaChart } from 'react-icons/ai'
import { FaUserTie } from 'react-icons/fa'
import { MdTrackChanges } from 'react-icons/md'
import { MdOutlineMobileFriendly } from "react-icons/md";
import { GiHumanTarget } from "react-icons/gi"
import { LiaFunnelDollarSolid } from "react-icons/lia";
import { FaRegFaceGrinStars } from "react-icons/fa6"
import { GrDocumentPerformance } from "react-icons/gr";
import { GiResize } from "react-icons/gi";
import { CgPerformance } from "react-icons/cg";
import { Link } from 'react-router-dom'

const InApp = () => {
    return (
        <div className='w-full'>

            <div className='w-full h-[80vh] md:h-[70vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl md:text-6xl text-center font-bold mt-16'>Maximize In-App Ad Success</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>
                    In-app advertising is a powerful strategy for marketers to connect with users within mobile applications, where engagement is often high. These ads seamlessly blend with the app's content, offering a non-intrusive experience to users.
                </p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full pt-10 flex flex-col items-center'>

                <div className='w-[95%] md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                            Keys to In-App Success
                        </h1>
                        <div className='w-full md:hidden md:w-[50%]'>
                            <img className='w-[100%] h-[100%] object-cover' src='/inapp1.jpg' alt='imagee' />
                        </div>
                        <p className='md:w-[80%] py-5 text-base md:text-lg text-center md:text-start text-black'>
                            Successful in-app campaigns require a balance between the right ad network, reaching the correct target audience, and being on-point with your creatives and messaging. If even one of these is off, then the campaign won’t reach its full potential.
                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>
                    <div className='w-full hidden md:block md:w-[50%]'>
                        <img className='w-[100%] h-[100%] object-cover' src='/inapp1.jpg' alt='imagee' />
                    </div>
                </div>

                <div className='w-[95%] mt-10 md:flex md:justify-between]'>
                    <div className='md:w-[55%] hidden md:flex'>
                        <img className='w-[100%] h-[100%] object-cover' src='/add2.jpg' alt='image2' />
                    </div>

                    <div className='md:w-[40%] px-5 flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                            Consumer Centric Ad Placement
                        </h1>

                        <div className='md:w-[55%] md:hidden'>
                            <img className='w-[100%] h-[100%] object-cover' src='/add2.jpg' alt='image2' />
                        </div>

                        <p className='py-5 text-base md:text-lg text-center md:text-start text-black'>
                            Keep in mind that marketing revolves around your customers, not yourself. Ensure that your ad placement is well-timed and contributes positively to the user experience. For instance, video ads are suitable during video content consumption but may feel intrusive when users are engaged in reading. Additionally, an excessive frequency of ads might alienate users from your brand altogether.
                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div className='w-full md:h-[95vh] bg-gradient-to-r from-purple-600 to-blue-600 py-10 md:pt-10 my-10 flex flex-col justify-center items-center'>
                <div className='w-[95%] text-white md:py-20 flex flex-col items-center'>
                    <h2 className='text-center text-3xl md:text-6xl font-bold'>Diverse Display Ad Formats</h2>
                    <p className='w-[80%] py-5 md:py-10 text-gray-100 font-medium text-xl text-center'>Amplify audience engagement with our team&apos;s professionally designed dynamic and interactive ads or select from our cutting-edge ready-to-use templates.</p>
                    <div className='w-full flex justify-center flex-wrap gap-4 md:gap-8'>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Display Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Video Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Native Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Dynamic Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Rich Media Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Roadblock Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interstitial Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interactive Ad</div>
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col my-20 justify-center'>
                    <h1 className='text-center text-3xl md:text-6xl textColor font-bold'>Why In-App Advertising?</h1>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <MdOutlineMobileFriendly className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Mobile Dominance</h1>
                            <p className='textColor font-medium text-center'>
                                In-app ads leverage mobile device prevalence, reaching users on a platform where they spend significant digital time, maximizing visibility and impact.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <GiResize className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Adaptive Ad Formats</h1>
                            <p className='textColor font-medium text-center'>
                                In-app ads accommodate diverse formats like native and interactive media, empowering advertisers to align with campaign goals and audience preferences effectively.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <LiaFunnelDollarSolid className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Higher Conversion Rates</h1>
                            <p className='textColor font-medium text-center'>
                                In-app ads, featuring targeted and engaging content, often lead to higher conversion rates, encouraging users to make purchases or download apps within the app environment.
                            </p>
                        </div>
                    </div>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <GiHumanTarget className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Personalized Targeting</h1>
                            <p className='textColor font-medium text-center'>
                            In-app ads empower brands to personalise messages, considering user behaviour, preferences, and demographics, enhancing ad relevance and resonance with the audience.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <FaRegFaceGrinStars className='text-7xl textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Better User Experience</h1>
                            <p className='textColor font-medium text-center'>
                            In-app ads offer flexible timing and mobile optimization, ensuring seamless user presentation, a feature often lacking in web-based ads.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                            <CgPerformance className='text-7xl textColor' />
                                
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Track Performance</h1>
                            <p className='textColor font-medium text-center'>
                            In-app ads are easier to monitor and adjust, being part of your digital space. This allows for prompt action and campaign performance improvements.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Banner />
        </div>
    )
}

export default InApp
