import React from 'react'
import Banner from '../Banner'
import { TbChartInfographic, TbTargetArrow } from 'react-icons/tb'
import { MdCampaign, MdGroups2 } from 'react-icons/md'
import { DiHtml53DEffects } from 'react-icons/di'
import { PiListMagnifyingGlass } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import { FaEye } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";
import { LiaFunnelDollarSolid } from "react-icons/lia";
import { HiOutlineLightBulb } from "react-icons/hi";



const Display = () => {
    return (
        <div className='w-full'>

            <div className='w-full h-[70vh] md:h-[60vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold mt-16'>Interstitial Ads</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>Capture attention with interstitial ads, seamlessly integrating into app experiences. Maximize engagement and conversions with these immersive ad formats. It&apos;s all about creating visually appealing ads that resonate with potential customers. By using real-time bidding and programmatic technology, you can efficiently allocate your ad inventory to meet the demand, ultimately boosting your ROI and turning clicks into conversions.</p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full pt-10 flex flex-col items-center'>

                <div className='w-[95%] md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                        Maximizing Engagement with Interstitials
                        </h1>
                        <div className='w-full md:hidden md:w-[50%] my-5'>
                            <img className='w-[100%] h-[100%] object-cover' src='/inte3.jpeg' alt='imagee' />
                        </div>
                        <p className='text-base md:text-lg text-center md:text-start text-black'>
                        Interstitial ads play a crucial role in digital marketing by capturing users' attention with full-screen displays, maximizing visibility and engagement.
                        </p>
                        <p className='text-base md:text-lg text-center md:text-start text-black pt-2'>
                        Their immersive nature ensures optimal exposure to promotional content, driving conversions and enhancing brand recognition effectively.

                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>
                    <div className='w-full hidden md:block md:w-[50%]'>
                        <img className='w-[100%] h-[100%] object-cover' src='/inte3.jpeg' alt='imagee' />
                    </div>
                </div>

                <div className='w-[95%] mt-10 md:flex md:justify-between]'>
                    <div className='md:w-[55%] hidden md:flex'>
                        <img className='w-[100%] h-[100%] object-cover' src='/inte2.jpeg' alt='image2' />
                    </div>

                    <div className='md:w-[40%] px-5 flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-5xl font-bold textColor pb-3'>
                        Immersive Brand Storytelling Experiences

                        </h1>

                        <div className='md:w-[55%] md:hidden my-5'>
                            <img className='w-[100%] h-[100%] object-cover' src='/inte2.jpeg' alt='image2' />
                        </div>

                        <p className='text-base md:text-lg text-center md:text-start text-black'>
                        Interstitial ads offer a dynamic platform for showcasing multimedia content, fostering brand storytelling and immersive experiences. By occupying the entire screen space during natural breaks in user activity, they captivate audiences, driving brand awareness and encouraging interaction effectively.
                        </p>
                        <div className='my-5'>
                            <a href='/contact'>
                                <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                            </a>
                        </div>
                    </div>

                </div>

            </div>

            <div className='w-full md:h-[95vh] bg-gradient-to-r from-purple-600 to-blue-600 py-10 md:pt-10 my-10 flex flex-col justify-center items-center'>
                <div className='w-[95%] text-white md:py-20 flex flex-col items-center'>
                    <h2 className='text-center text-3xl md:text-6xl font-bold'>Diverse Display Ad Formats</h2>
                    <p className='w-[80%] py-5 md:py-10 text-gray-100 font-medium text-xl text-center'>Amplify audience engagement with our team&apos;s professionally designed dynamic and interactive ads or select from our cutting-edge ready-to-use templates.</p>
                    <div className='w-full flex justify-center flex-wrap gap-4 md:gap-8'>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Display Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Video Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Native Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Dynamic Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Rich Media Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Roadblock Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interstitial Ad</div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>Interactive Ad</div>
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col my-20 justify-center'>
                    <h1 className='text-center text-3xl md:text-6xl textColor font-bold'>Why Interstitial Ads?</h1>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366415d125b7db6ad7f46ed_Transparency%20icon.svg' /> */}
                                <FaEye className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>High Visibility</h1>
                            <p className='textColor font-medium text-center'>
                            Occupying full screen ensures maximum exposure, boosting brand recognition and recall.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664428cb0f68cf2e5b1fe8_Target%20audience%20icon.svg' /> */}
                                <MdCampaign className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Increased Engagement</h1>
                            <p className='textColor font-medium text-center'>
                            Captivating multimedia content encourages user interaction, driving higher click-through rates.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664433943c1ac401d20c78_Placement%20icon.svg' /> */}
                                <TbTargetArrow className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Enhanced Targeting</h1>
                            <p className='textColor font-medium text-center'>
                            Precise audience segmentation ensures ads reach relevant users, optimizing campaign effectiveness.

                            </p>
                        </div>
                    </div>

                    <div className='w-full flex flex-wrap md:flex-nowrap gap-14 mt-14'>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366446818df79d2bd0b6825_Audience%20icon1.svg' /> */}
                                <LiaFunnelDollarSolid className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Improved Conversion Rates</h1>
                            <p className='textColor font-medium text-center'>
                            Seamless integration into user experience facilitates direct response, leading to higher conversions.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664477bd2623d65822bf77_Mobile%20verification%20icon.svg' /> */}
                                <HiOutlineLightBulb className='text-[80px] textColor' />
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Flexible Creativity</h1>
                            <p className='textColor font-medium text-center'>
                            Supports various multimedia content types, allowing creative flexibility to convey brand message effectively.
                            </p>
                        </div>
                        <div className='w-full border h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-lg hover:shadow-2xl rounded-xl'>
                            <div className='w-20 h-20'>
                                <FaDollarSign className='text-[80px] textColor' />
                                {/* <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366447fcaac03448cecb3f6_Creatives%20icon.svg' /> */}
                            </div>
                            <h1 className='font-bold text-2xl text-center'>Optimized ROI</h1>
                            <p className='textColor font-medium text-center'>
                            Efficient ad delivery and high engagement rates translate to better returns on investment for advertisers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Banner />
        </div>
    )
}

export default Display
