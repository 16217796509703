import React, { useState } from "react";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { BsFillSendFill } from "react-icons/bs";
import Banner from "../Banner";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Http from "../../Http";
import { Contact_API } from "../../Helper";

const Contact = () => {
  const initialFormData = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      const pattern = /^[A-Za-z\s]+$/;
      if (!pattern.test(value)) {
        setError("Name should contain only letters and spaces.");
      } else {
        setError(null);
      }
    }
  
    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setError("Please enter a valid email address.");
      } else {
        setError(null);
      }
    }
  
  

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    if (!formData.name.trim() || !formData.email.trim() || !formData.subject.trim() || !formData.message.trim()) {
      setError("Please fill out all fields.");
      return;
    }

    
    if (error) {
      return;
    }

    setLoading(true);

    try {
      const response = await Http.post(Contact_API, formData);
      console.log("Response:", response);
      setSuccess(true);
      resetForm();
    } catch (error) {
      console.error("Error sending data:", error);
      setError("Failed to send data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const slides = [
    { image: '/swiggy.png' },
    { image: '/zivame.jpeg' },
    { image: '/tata.png' },
    { image: '/citroen.jpeg' },
    { image: '/slider5.jpg' },
    { image: '/slider6.jpg' },
    { image: '/slider7.jpg' },
    { image: '/slider8.jpg' },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="w-full">
        <div className="w-full bgColor md:h-screen flex justify-center items-center">
          <div className="w-[95%] md:mt-20 flex flex-wrap md:flex-nowrap items-center md:justify-center gap-5">
            <div className="md:w-[60%] rounded-xl overflow-hidden text-white hidden md:flex md:flex-col items-center md:items-start justify-center h-[500px]">
              <h1 className="text-5xl font-semibold">Contact Now</h1>
              <p className="py-5 text-xl">
                Our dedicated team will respond promptly to assist you.
              </p>
              <div className="w-full h-[300px]">
                <div className="pr-2">
                  <img
                    className="w-[100%] h-[100%] object-contain"
                    src="/contact4.jpg"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[60%] flex justify-center items-center textColor mt-28 mb-10 md:mb-0 md:mt-0">
              <div
                style={{ backgroundImage: `url('/contactBackground.jpg')` }}
                className="md:w-[80%] bg-cover bg-bottom w-full md:bg-white md:border px-2 md:px-8 py-5 md:py-8 md:shadow-lg md:shadow-gray-900 rounded-xl"
              >
                <h1 className="text-3xl md:text-4xl text-center font-bold mb-5 flex items-center gap-3 w-full justify-center">
                  Get In Touch
                </h1>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 md:mb-4">
                    <div className="flex gap-3 md:gap-5">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border text-gray-500 rounded-md"
                        required
                      />

                    
                    </div>
                  </div>

                  <div className="mb-3 md:mb-4 flex gap-3 md:gap-5">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full mt-1 p-2 border text-gray-500 rounded-md"
                      required
                    />
                  </div>
                  <input
                    type="text"
                    name="subject"
                    placeholder='Subject'
                    value={formData.subject}
                    onChange={handleChange}
                    className="w-full mt-1 mb-3 md:mb-4 p-2 border text-gray-500 rounded-md"
                    required
                  />

                  <div className="mb-3 md:mb-4">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full h-[180px] md:h-auto mt-1 p-2 border rounded-md text-gray-500"
                      placeholder='Write Your Messege'
                      rows="4"
                     
                    ></textarea>
                  </div>
                  <div className="flex w-full ">
                    <button type="submit" className="w-full flex bgColor text-white font-medium  py-3 rounded  items-center justify-center" disabled={loading}>
                      {loading ? "Sending..." : "Submit "}
                      <BsFillSendFill className="text-xl" />
                    </button>
                  </div>
                </form>
                {error && <p>{error}</p>}
                {success && <p>Form submitted successfully!</p>}
              </div>
            </div>

            <div className="md:w-[60%] rounded-xl overflow-hidden text-white md:hidden md:flex-col items-center md:items-start justify-center h-[500px]">
              <h1 className="text-center md:text-start text-3xl md:text-5xl font-semibold">
                Contact us anytime
              </h1>
              <p className="py-5 text-lg text-center md:text-start md:text-xl">
                Our dedicated team will respond promptly to assist you.
              </p>
              <div className="w-full h-[300px]">
                <img
                  className="w-[100%] h-[100%] object-contain "
                  src="contact4.jpg"
                  alt="banner"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col items-center my-5 md:my-10">
          <div className="w-[95%] p-2 md:mt-10">
          <div className='container mt-16 font-Kanit, sans-serif'>
          <div className="text-center my-10">
              <h1 className="text-center text-3xl md:text-6xl textColor font-bold mt-10">
                Our Clientele
              </h1>
              {/* <h2 className='pt-10 pb-2 text-2xl font-bold'>Integrated Supply Partners</h2> */}
              <p className="text-xl font-medium text-black py-5">
                We proudly serve a diverse clientele, spanning various
                industries and backgrounds, reflecting our commitment to
                inclusivity and adaptability.
              </p>
            </div>
            <Slider {...settings}>
  {slides.map((slide, index) => (
    <div key={index}>
      <img
        src={process.env.PUBLIC_URL + slide.image}
        alt={`Image ${index + 1}`}
        style={{ width: '90%', height: '150px', border: '1px solid #' }}
      />
    </div>
  ))}
</Slider>
    </div>
          </div>
        </div>

        <Banner />
      </div>
    </>
  );
};

export default Contact;

// import React, { useState } from "react";
// import Footer from "../Footer";
// import NavBar from "../NavBar";
// import {
//   BsFillPhoneVibrateFill,
//   BsFillSendFill,
//   BsFillTelephoneOutboundFill,
// } from "react-icons/bs";
// import Banner from "../Banner";

// const Contact = () => {
//     const initialFormData = {
//       firstName: "",
//       lastName: "",
//       email: "",
//       phoneNumber: "",
//       companyName: "",
//       message: "",
//     };
  
//     const [formData, setFormData] = useState(initialFormData);
//     const [loading, setLoading] = useState(false);
  
//     const handleChange = (e) => {
//       const { name, value } = e.target;
//       setFormData((prevData) => ({ ...prevData, [name]: value }));
//     };
  
//     const handleSubmit = async (e) => {
//       e.preventDefault();
//       setLoading(true);
  
//       try {
//         // Simulate an asynchronous operation (e.g., API call)
//         await sendFormDataToServer(formData);
  
//         // Store data in local storage
//         localStorage.setItem("contactFormData", JSON.stringify(formData));
//         console.log("Form Data:", formData);
  
//         resetForm();
//       } catch (error) {
//         console.error("Error sending data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     const sendFormDataToServer = async (data) => {
//       // Simulate an asynchronous operation (e.g., API call)
//       return new Promise((resolve) => {
//         setTimeout(() => {
//           resolve();
//         }, 2000); // Simulating a 2-second delay
//       });
//     };
  
//     const resetForm = () => {
//       setFormData(initialFormData);
//     };
  

//   return (
//     <>
      // <div className="w-full">
      //   <div className="w-full bgColor md:h-screen flex justify-center items-center">
      //     <div className="w-[95%] md:mt-20 flex flex-wrap md:flex-nowrap items-center md:justify-center gap-5">
      //       <div className="md:w-[60%] rounded-xl overflow-hidden text-white hidden md:flex md:flex-col items-center md:items-start justify-center h-[500px]">
      //         <h1 className="text-5xl font-semibold">Contact us anytime</h1>
//               <p className="py-5 text-xl">
//                 Our dedicated team will respond promptly to assist you.
//               </p>
//               <div className="w-full h-[300px]">
//                 <div className="pr-2">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/Contact1.jpg"
//                     alt="banner"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="w-full md:w-[60%] flex justify-center items-center textColor mt-28 mb-10 md:mb-0 md:mt-0">
//               <div
//                 style={{ backgroundImage: `url('/contactBackground.jpg')` }}
//                 className="md:w-[80%] bg-cover bg-bottom w-full md:bg-white md:border px-2 md:px-8 py-5 md:py-8 md:shadow-lg md:shadow-gray-900 rounded-xl"
//               >
//                 <h1 className="text-3xl md:text-4xl text-center font-bold mb-5 flex items-center gap-3 w-full justify-center">
//                   Contact Now
//                 </h1>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3 md:mb-4">
//                     <div className="flex gap-3 md:gap-5">
//                       <input
//                         type="text"
//                         name="firstName"
//                         placeholder="First Name"
//                         value={formData.firstName}
//                         onChange={handleChange}
//                         className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                         required
//                       />

//                       <input
//                         type="text"
//                         name="lastName"
//                         placeholder="Last Name"
//                         value={formData.lastName}
//                         onChange={handleChange}
//                         className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                         required
//                       />
//                     </div>
//                   </div>

//                   <div className="mb-3 md:mb-4 flex gap-3 md:gap-5">
//                     <input
//                       type="email"
//                       name="email"
//                       placeholder="Email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                       required
//                     />
//                     <input
//                       type="tel"
//                       name="phoneNumber"
//                       placeholder="Phone Number"
//                       value={formData.phoneNumber}
//                       onChange={handleChange}
//                       className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                     />
//                   </div>
//                   <input
//                     type="text"
//                     name="companyName"
//                     placeholder='Company Name '
//                     value={formData.companyName}
//                     onChange={handleChange}
//                     className="w-full mt-1 mb-3 md:mb-4 p-2 border text-gray-500 rounded-md"
//                   />

//                   <div className="mb-3 md:mb-4">
//                     <textarea
//                       name="message"
//                       value={formData.message}
//                       onChange={handleChange}
//                       className="w-full h-[180px] md:h-auto mt-1 p-2 border rounded-md text-gray-500"
//                       placeholder='Write Your Messege'
//                       rows="4"
//                       required
//                     ></textarea>
//                   </div>
//                   <div className="flex w-full">
//                     <button
//                       type="submit"
//                       className="bgColor text-white font-medium w-full py-3 rounded flex items-center justify-center"
//                        disabled={loading}
//                     >
//                      {loading ? "Sending..." : "Send"}
//                       <BsFillSendFill className="text-xl" />
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>

//             <div className="md:w-[60%] rounded-xl overflow-hidden text-white md:hidden md:flex-col items-center md:items-start justify-center h-[500px]">
//               <h1 className="text-center md:text-start text-3xl md:text-5xl font-semibold">
//                 Contact us anytime
//               </h1>
//               <p className="py-5 text-lg text-center md:text-start md:text-xl">
//                 Our dedicated team will respond promptly to assist you.
//               </p>
//               <div className="w-full h-[300px]">
//                 <img
//                   className="w-[100%] h-[100%] object-contain "
//                   src="Contact1.jpg"
//                   alt="banner"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="w-full flex flex-col items-center my-5 md:my-10">
//           <div className="w-[95%] p-2 md:mt-10">
//             <div className="text-center my-10">
//               <h1 className="text-center text-3xl md:text-6xl textColor font-bold mt-10">
//                 Our Clientele
//               </h1>
//               {/* <h2 className='pt-10 pb-2 text-2xl font-bold'>Integrated Supply Partners</h2> */}
//               <p className="text-xl font-medium textColor py-5">
//                 We proudly serve a diverse clientele, spanning various
//                 industries and backgrounds, reflecting our commitment to
//                 inclusivity and adaptability.
//               </p>
//             </div>
//             <div className="w-full flex flex-wrap md:flex-nowrap gap-5 md:gap-2 justify-center md:justify-between">
//               <div className="flex justify-center items-center overflow-hidden w-32 h-32">
//                 <img
//                   className="w-[100%] h-[100%] object-contain"
//                   src="/aajtak.png"
//                 />
//               </div>
//               <div className="flex justify-center items-center overflow-hidden w-32 h-32">
//                 <img
//                   className="w-[100%] h-[100%] object-contain"
//                   src="/swiggy.png"
//                 />
//               </div>
//               <div className="flex justify-center items-center overflow-hidden w-32 h-32">
//                 <img
//                   className="w-[100%] h-[100%] object-contain"
//                   src="/zivame.jpeg"
//                 />
//               </div>
//               <div className="flex justify-center items-center overflow-hidden w-32 h-32">
//                 <img
//                   className="w-[100%] h-[100%] object-contain"
//                   src="/tata.png"
//                 />
//               </div>
//               <div className="flex justify-center items-center overflow-hidden w-32 h-32">
//                 <img
//                   className="w-[100%] h-[100%] object-contain"
//                   src="/citroen.jpeg"
//                 />
//               </div>
//               <div className="flex justify-center items-center overflow-hidden w-32 h-32">
//                 <img
//                   className="w-[100%] h-[100%] object-contain"
//                   src="/zee5.png"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>

//         <Banner />
//       </div>
//     </>
//   );
// };

// export default Contact;

// import React, { useState } from 'react'
// import Footer from '../Footer'
// import NavBar from '../NavBar';
// import { BsFillPhoneVibrateFill, BsFillSendFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
// import Banner from '../Banner';

// const Contact = () => {
//     const [formData, setFormData] = useState({
//         selectOption: '',
//         radioButton: '',
//         textArea: '',
//         textInput: '',
//         date: '', // Added date field
//         checkbox: false,
//     });

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData({
//             ...formData,
//             [name]: type === 'checkbox' ? checked : value,
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // You can submit the form data here or perform any other action.
//         console.log(formData);
//     };

//     return (
//         <>
//             <div className='w-full'>

//                 <div className='w-full bgColor md:h-screen flex justify-center items-center'>
//                     <div className='w-[95%] md:mt-20 flex flex-wrap md:flex-nowrap items-center md:justify-center gap-5'>

//                         <div className='md:w-[60%] rounded-xl overflow-hidden text-white hidden md:flex md:flex-col items-center md:items-start justify-center h-[500px]'>
//                             <h1 className='text-5xl font-semibold'>Contact us anytime</h1>
//                             <p className='py-5 text-xl'>Our dedicated team will respond promptly to assist you.</p>
//                             <div className='w-full h-[300px]'>
//                             <div className="pr-2">
//                                 <img className='w-[100%] h-[100%] object-contain' src='/Contact1.jpg' alt='banner' />
//                             </div>
//                             </div>
//                         </div>

//                         <div className='w-full md:w-[60%] flex justify-center items-center textColor mt-28 mb-10 md:mb-0 md:mt-0'>
//                             <div style={{ backgroundImage: `url('/contactBackground.jpg')` }} className="md:w-[80%] bg-cover bg-bottom w-full md:bg-white md:border px-2 md:px-8 py-5 md:py-8 md:shadow-lg md:shadow-gray-900 rounded-xl">
//                                 <h1 className='text-3xl md:text-4xl text-center font-bold mb-5 flex items-center gap-3 w-full justify-center'>
//                                     Contact Now
//                                 </h1>
//                                 <form >
//                                     <div className="mb-3 md:mb-4">
//                                         <div className='flex gap-3 md:gap-5'>
//                                             <input
//                                                 type="text"
//                                                 name="textInput"
//                                                 id="textInput"
//                                                 value=""
//                                                 placeholder='First Name'
//                                                 className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                                             />
//                                             <input
//                                                 type="text"
//                                                 name="textInput"
//                                                 id="textInput"
//                                                 value=""
//                                                 placeholder='Last Name'
//                                                 className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                                             />
//                                         </div>
//                                     </div>

//                                     <div className="mb-3 md:mb-4 flex gap-3 md:gap-5">
//                                         <input
//                                             type="email"
//                                             name="textInput"
//                                             id="textInput"
//                                             value=""
//                                             placeholder='Email Address'
//                                             className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                                         />
//                                         <input
//                                             type="number"
//                                             name="textInput"
//                                             id="textInput"
//                                             value=""
//                                             placeholder='Phone Number'
//                                             className="w-full mt-1 p-2 border text-gray-500 rounded-md"
//                                         />
//                                     </div>

//                                     <input
//                                         type="text"
//                                         name="textInput"
//                                         id="textInput"
//                                         value=""
//                                         placeholder='Company Name'
//                                         className="w-full mt-1 mb-3 md:mb-4 p-2 border text-gray-500 rounded-md"
//                                     />

//                                     <div className="mb-3 md:mb-4">
//                                         <textarea
//                                             name="textArea"
//                                             id="textArea"
//                                             value=""
//                                             className="w-full h-[180px] md:h-auto mt-1 p-2 border rounded-md text-gray-500"
//                                             placeholder='Write Your Messege'
//                                             rows="4"
//                                         ></textarea>
//                                     </div>
//                                     <div className='flex w-full'>
//                                         <button
//                                             type="submit"
//                                             className="bgColor text-white font-medium w-full py-3 rounded flex items-center justify-center"
//                                         >
//                                             <BsFillSendFill className='text-xl' />
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>

//                         <div className='md:w-[60%] rounded-xl overflow-hidden text-white md:hidden md:flex-col items-center md:items-start justify-center h-[500px]'>
//                             <h1 className='text-center md:text-start text-3xl md:text-5xl font-semibold'>Contact us anytime</h1>
//                             <p className='py-5 text-lg text-center md:text-start md:text-xl'>Our dedicated team will respond promptly to assist you.</p>
//                             <div className='w-full h-[300px]'>
//                                 <img className='w-[100%] h-[100%] object-contain ' src='Contact1.jpg' alt='banner' />
//                             </div>
//                         </div>

//                     </div>
//                 </div>

//                 <div className='w-full flex flex-col items-center my-5 md:my-10'>
//                     <div className='w-[95%] p-2 md:mt-10'>
//                         <div className='text-center my-10'>
//                             <h1 className='text-center text-3xl md:text-6xl textColor font-bold mt-10'>Our Clientele</h1>
//                             {/* <h2 className='pt-10 pb-2 text-2xl font-bold'>Integrated Supply Partners</h2> */}
//                             <p className='text-xl font-medium textColor py-5'>
//                                 We proudly serve a diverse clientele, spanning various industries and backgrounds, reflecting our commitment to inclusivity and adaptability.
//                             </p>
//                         </div>
//                         <div className='w-full flex flex-wrap md:flex-nowrap gap-5 md:gap-2 justify-center md:justify-between'>
//                             <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                 <img className='w-[100%] h-[100%] object-contain' src='/aajtak.png' />
//                             </div>
//                             <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                 <img className='w-[100%] h-[100%] object-contain' src='/swiggy.png' />
//                             </div>
//                             <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                 <img className='w-[100%] h-[100%] object-contain' src='/zivame.jpeg' />
//                             </div>
//                             <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                 <img className='w-[100%] h-[100%] object-contain' src='/tata.png' />
//                             </div>
//                             <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                 <img className='w-[100%] h-[100%] object-contain' src='/citroen.jpeg' />
//                             </div>
//                             <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                 <img className='w-[100%] h-[100%] object-contain' src='/zee5.png' />
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//                 <Banner />

//             </div>
//         </>
//     )
// }

// export default Contact
