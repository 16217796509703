'use client';
import { Accordion } from 'flowbite-react'
import React from 'react'

const CardAccordion = () => {
    return (
        <>
            <Accordion>
                <Accordion.Panel>
                    <Accordion.Title>
                        Room rent limit
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            <a
                                className="text-cyan-600 hover:underline dark:text-cyan-500"
                                href="https://flowbite.com/docs/getting-started/introduction/"
                            >
                                <p>
                                    What you should know
                                </p>
                            </a>
                            <p>
                                Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Look out for plans with No Room Rent limit
                            </p>
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        Restoration of cover
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            <p>
                                Rs 5 lakh unlimited times a year; for related and unrelated illness
                            </p>
                        </p>
                        <p className="text-gray-500 dark:text-gray-400">

                            <a
                                className="text-cyan-600 hover:underline dark:text-cyan-500"
                                href="https://flowbite.com/figma/"
                            >
                                <p>
                                    What you should know
                                </p>
                            </a>
                            <p>
                                Very useful feature, in case the sum Insured gets exhausted in a year due to multiple claims or a large claim. Insurer restores the sum insured to provide continued coverage to policyholders.
                            </p>
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        No claim bonus
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            <p>
                                Rs 50,000 per year; up to maximum of Rs 5 lakh
                            </p>
                        </p>
                        <p className="text-gray-500 dark:text-gray-400">

                            <a
                                className="text-cyan-600 hover:underline dark:text-cyan-500"
                                href="https://flowbite.com/figma/"
                            >
                                <p>
                                    What you should know
                                </p>
                            </a>
                            <p>
                                No Claim Bonus is the reward for not making a claim. Depending on plan, Insurer adds a 5%-100% bonus to the Sum Insured at no extra premium.
                            </p>
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        No claim bonus
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            <p>
                                Rs 50,000 per year; up to maximum of Rs 5 lakh
                            </p>
                        </p>
                        <p className="text-gray-500 dark:text-gray-400">

                            <a
                                className="text-cyan-600 hover:underline dark:text-cyan-500"
                                href="https://flowbite.com/figma/"
                            >
                                <p>
                                    What you should know
                                </p>
                            </a>
                            <p>
                                No Claim Bonus is the reward for not making a claim. Depending on plan, Insurer adds a 5%-100% bonus to the Sum Insured at no extra premium.
                            </p>
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>
        </>
    )
}

export default CardAccordion
