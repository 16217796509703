import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 lg:px-8 py-8 mt-10">
      <h1 className="text-3xl lg:text-4xl font-bold mb-4">Terms and Conditions</h1>

      <p className="mb-4 lg:mb-8">
      By accessing and using the BidBox website and services, you agree to be bound by these Terms and Conditions. Please read these terms carefully before using our platform.

      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">1. Use of Services</h2>
      <p className="mb-4 lg:mb-3">
        1.1. You must be at least 18 years old to use BidBox. By using our services, you represent that you are of legal age to form a binding contract.
      </p>
      <p className="mb-4 lg:mb-6">
        1.2. You agree to use BidBox only for lawful purposes and in accordance with these Terms and Conditions, as well as any applicable laws and regulations.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">2. Account Registration</h2>
      <p className="mb-4 lg:mb-3">
        2.1. To access certain features of BidBox, you may be required to register for an account. You agree to provide accurate and complete information when registering and to keep your account information updated.
      </p>
      <p className="mb-4 lg:mb-6">
      2.2. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">3. Intellectual Property</h2>
      <p className="mb-4 lg:mb-3">
      3.1. The content and materials available on BidBox, including but not limited to text, graphics, logos, images, and software, are owned by or licensed to BidBox and are protected by copyright, trademark, and other intellectual property laws.
      </p>
      <p className="mb-4 lg:mb-6">
      3.2. You may not use, reproduce, distribute, or modify any content from BidBox without the express written consent of BidBox.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">4. Privacy</h2>
      <p className="mb-4 lg:mb-6">
      4.1. Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and safeguard your personal information.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">5. Limitation of Liability</h2>
      <p className="mb-4 lg:mb-6">
      5.1. In no event shall BidBox, its directors, officers, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of BidBox or the content available on it.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">6. Termination</h2>
      <p className="mb-4 lg:mb-6">
      6.1. BidBox reserves the right to terminate or suspend your access to the platform at any time, without prior notice, for any reason or no reason, including but not limited to violation of these Terms and Conditions.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">7. Termination</h2>
      <p className="mb-4 lg:mb-6">
      7.1. These Terms and Conditions shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">8. Changes to Terms</h2>
      <p className="mb-4 lg:mb-6">
      8.1. BidBox reserves the right to update or revise these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on this page.
      </p>
      
    

      {/* <h2 className="text-xl lg:text-2xl font-bold mb-2">Contact Us</h2>
      <p className="mb-4 lg:mb-6">
        If you have any questions or concerns about these Terms and Conditions, please contact us at : <a href="mailto:Team@zonemedia.co" className="text-blue-800 underline">Team@zonemedia.co</a>.
      </p> */}
      
    </div>
  );
};

export default TermsAndConditions;
