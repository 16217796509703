import React from "react";
import CaseStudyCard from "./CaseStudyCard";

const Bfsi = () => {
  return (
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold text-center mt-14">
          Banking and Financial Services
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/Yes Securities.png"
                heading="Navigating Success with Yes Securities"
                impression="Partnership Since : 2022 "
                vtr="New Customers Acquired : 24500+"
                content="In strategically partnering with Yes Securities, our aim was clear: reimagine the brand and establish a formidable presence in the financial sector. This comprehensive strategy not only elevated brand recognition but also drove new users to Yes Securities, solidifying its position as a trusted financial partner. "
                content1="Our campaign specifically targeted a broad and relevant audience interested in trading and investing, leveraging regional inventories and fintech websites for precise outreach.  Through meticulous branding initiatives, we forged a unique identity seamlessly blending tradition with innovation."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
              {/* <CaseStudyCard
                                image="/angel.jpg"
                                heading="Driving Growth at Angel One"
                                impression='Account Open : 43500+'
                                vtr='VTR 97% +'
                                content='We have played a pivotal role in Angel One remarkable ascent to the pinnacle of trading platforms. Collaboratively, we've successfully executed over 10 campaigns, strategically targeting specific audiences and optimizing ad placements and inventories.'
                                
                                content1='This concerted effort has not only elevated Angel One' market presence but has also fueled significant growth in new account openings. Our enduring partnership with the brand stands as a testament to our ability to not just generate traffic, but to convert it into loyal consumers, showcasing our commitment to driving both visibility and consumer engagement.'
                                // content2="The success of this branding initiative stands as a pivotal achievement in the brand's journey toward industry prominence and the cultivation of enduring client confidence."
                            /> */}
              <CaseStudyCard
                image="/angel.jpg"
                heading="Smart Investments, Secure Future with Angel One"
                impression="Campaigns Launched : 15+"
                vtr="New Customers Acquired : 43500+"
                content="We have played a pivotal role in Angel One's remarkable ascent to the pinnacle of trading platforms. Collaboratively, we've successfully executed over 10 campaigns, strategically targeting specific audiences and optimizing ad placements and inventories."
                content1="This concerted effort has not only elevated Angel One's market presence but has also fueled significant growth in new account openings. Our enduring partnership with the brand stands as a testament to our ability to not just generate traffic but to convert it into loyal consumers, showcasing our commitment to driving both visibility and consumer engagement."
                // content2="The success of this branding initiative stands as a pivotal achievement in the brand's journey toward industry prominence and the cultivation of enduring client confidence."
              />
              <CaseStudyCard
                image="/Maybank 2.jpg"
                heading="Maybank's Resounding Branding Success"
                impression="Impressions : 3760000+"
                vtr="CTR: 3.2%"
                content="In a strategic endeavor to bolster Maybank's brand awareness in the Indonesian market, our mission was clear: create a lasting impact. Employing an innovative campaign, we seamlessly integrated traditional and digital channels, capturing the diverse Indonesian audience. The results were exceptional, as Maybank swiftly evolved into a recognized and trusted financial entity."
                content1="This case study exemplifies our skill in crafting impactful branding strategies, showcasing Maybank's triumphant emergence as a prominent and dependable figure in the dynamic Indonesian financial landscape. The campaign's success signifies a significant milestone, solidifying Maybank's brand presence and resonance within the vibrant Indonesian market."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                <h1 className="text-white font-bold text-xl md:text-5xl">
                 Questions or ready to get started?
              </h1>
              <h2 className="text-white  text-xl md:text-2xl">
                Reach out and let's supercharge your campaigns together!
              </h2>
                </div>
                <div>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                  Get Started
                </button>
              </a>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bfsi;
