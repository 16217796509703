import React from "react";
import CaseStudyCard from "./CaseStudyCard";

const Fmcg = () => {
  return (
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold  text-center mt-14">
          Consumer Packaged Goods
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/ThumsUpLogo.jpg"
                heading="Fizzing Excitement with Thums Up"
                impression="Impressions 20 Million+"
                vtr="CTR: 2.3%"
                content="In our dynamic partnership with Thums Up leading up to the Cricket World Cup, our mission was clear: boost brand visibility through strategic programmatic advertising. Crafting tailored campaigns for diverse audiences across P1 & P2 markets and regional inventories, we achieved unparalleled brand recognition."
                content1="Precision targeting and real-time optimization led to a significant increase in Thums Up's awareness.The dynamic results underscore the effectiveness of strategic programmatic initiatives in amplifying brand presence before a major sporting event."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
              <CaseStudyCard
                image="/smallindofood.jpg"
                heading="Savoring Success  with  Indofoods"
                impression="Reach: 8,53,278"
                vtr="VTR: 98%"
                content="In our strategic initiative to elevate Indofoods' brand presence in the Indonesian market, we harnessed the dynamic potential of programmatic advertising. Meticulously crafting targeted strategies, we seamlessly resonated with the diverse Indonesian audience."
                content1="With a VTR exceeding 90% and specific targeting cohorts such as fast food engagers, people looking for ready-to-cook food products, it highlights Indofoods' successful ascent in the competitive landscape of the Indonesian market."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
              <CaseStudyCard
                image="/alfanew.jpg"
                heading="Alfagifts' Indonesian Market Soar"
                impression="Impressions: 6 Mn+"
                vtr="VTR: 90%"
                content="In a targeted campaign to strengthen Alfagifts' brand presence in Indonesia, we executed a nuanced programmatic strategy utilizing both web and app traffic. Leveraging the agility of Bidbox, our approach included a mix of impactful native ads and banner ads precisely tailored for the diverse Indonesian audience."
                content1="The outcome was remarkable: Alfagifts experienced a significant surge in brand recognition and sales. This case study highlights our expertise in leveraging programmatic advertising across web and app platforms, exceeding branding goals and solidifying Alfagifts as a standout force in the dynamic Indonesian market."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                     Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fmcg;
