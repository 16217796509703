import React from 'react'
import { BsTwitter } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebookF, FaSnapchatGhost } from 'react-icons/fa'

const Footer = () => {
    return (
        <div className='w-full bgColor flex flex-col items-center overflow-hidden'>
            <div className='w-[95%] flex flex-wrap justify-between my-10'>
                {/* <div className='py-5 w-[50%] md:w-auto'>
                    <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Company</h1>
                    <p className='text-center text-white'>Advertising</p>
                    <p className='text-center text-white py-1'>Affiliate Marketing</p>
                    <p className='text-center text-white'>Development</p>
                </div> */}
                <div className='py-5 w-[50%] md:w-auto'>
                    <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Solutions</h1>
                    <a href='awareness'>
                        <p className='text-center text-white'>Awareness</p>
                    </a>
                    <a href='re-targeting'>
                        <p className='text-center text-white py-1'>Re-Targeting</p>
                    </a>
                    {/* <p className='text-center text-white'>Legacy</p> */}
                </div>
                <div className='py-5 w-[50%] md:w-auto'>
                    <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Channel</h1>
                    <a href='/display'>
                        <p className='text-center text-white'>Display</p>
                    </a>
                    <a href='in-app'>
                        <p className='text-center text-white '>In-App</p>
                    </a>
                    <a href='ctv'>
                        <p className='text-center text-white'>CTV</p>
                    </a>
                    <a href='/video'>
                        <p className='text-center text-white'>Video</p>
                    </a>
                    <a href='/push-ads'>
                        <p className='text-center text-white'>Push-Ads</p>
                    </a>
                    <a href='/native-ads'>
                        <p className='text-center text-white'>Native-Ads</p>
                    </a>
                    <a href='/interstitial-ads'>
                        <p className='text-center text-white'>Interstitial-Ads</p>
                    </a>
                </div>
                <div className='py-5 w-[50%] md:w-auto'>
                    <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Case Studies</h1>
                    <a href='/bfsi'>
                        <p className='text-center text-white'>BFSI</p>
                    </a>
                    <a href='/fmcg'>
                        <p className='text-center text-white '>FMCG</p>
                    </a>
                    <a href='/entertainment'>
                        <p className='text-center text-white'>Entertainment</p>
                    </a>
                    <a href='/automobile'>
                        <p className='text-center text-white'>Automobile</p>
                    </a>
                    <a href='/gaming'>
                        <p className='text-center text-white'>Fashion & Lifestyle</p>
                    </a>
                    <a href='/aviation'>
                        <p className='text-center text-white'>Electronics</p>
                    </a>
                </div>
                <div className='py-5 w-[50%] md:w-auto'>
                    <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Company</h1>
                    
                    <a href='/about'>
                        <p className='text-center text-white'>About Us</p>
                    </a>
                    <a href='/contact'>
                        <p className='text-center text-white '>Contact Us</p>
                    </a>
                    <a href='/privacy-policy'>
                        <p className='text-center text-white py-1'>Privacy Policy</p>
                    </a>
                    <a href='/terms-and-conditions'>
                        <p className='text-center text-white '>Terms And Conditions</p>
                    </a>
                    {/* <p className='text-center text-white'>Benefits</p> */}
                </div>
            </div>

            <div className='w-[95%] mb-5'>
                <div className='flex gap-5 justify-center mb-4'>
                    <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
                        <FaFacebookF className='text-3xl textColor' />
                    </div>
                    <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
                        <BsTwitter className='text-3xl textColor' />
                    </div>
                    <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
                        <FaSnapchatGhost className='text-3xl textColor' />
                    </div>
                    <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
                        <AiFillInstagram className='text-3xl textColor' />
                    </div>
                </div>
                <p className='text-white text-center'>Bidbox.ai © 2024</p>
            </div>
        </div>
    )
}

export default Footer
