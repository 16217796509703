import React from 'react'
import { PiWechatLogoBold } from 'react-icons/pi'
import { BiLike, BiStar } from 'react-icons/bi'

const CreativeServices = () => {
    return (
        <>
            <div className='w-full'>
                <div className='w-full h-screen flex justify-center'>
                    <div className='w-[95%] flex flex-col items-center'>

                        <h1 className='pt-40 text-5xl text-center font-bold w-[60%]'>Here&apos;s how we make creatives that exceed your KPIs</h1>
                        <p className='py-5 pb-7 text-center text-xl font-bold textColor'>Our designers make your ideas happen based on data that comes from hundreds of successful campaigns.</p>

                        <div className='flex w-full justify-between gap-10 my-10'>
                            <div className='w-full hover:bg-[#00308F] hover:text-[#FBF8BE] textColor flex flex-col items-center rounded-3xl p-2 py-3 shadow-2xl hover:shadow-2xl'>
                                <PiWechatLogoBold className='text-5xl mt-2' />
                                <h2 className='text-center text-2xl font-semibold pt-3'>Make a request</h2>
                                <p className='text-center pt-1'>Our capabilities cover everything from game ads to drag & drop concepts to other fully custom html5 banners.</p>
                            </div>
                            <div className='w-full bgColor text-[#FBF8BE] flex flex-col items-center rounded-3xl p-2 scale-105 shadow-2xl'>
                                <BiLike className='text-5xl mt-2' />
                                <h2 className='text-center text-2xl font-semibold pt-3'>Give feedback</h2>
                                <p className='text-center pt-1'>Our capabilities cover everything from game ads to drag & drop concepts to other fully custom html5 banners.</p>
                            </div>
                            <div className='w-full hover:bg-[#00308F] hover:text-[#FBF8BE] textColor flex flex-col items-center rounded-3xl p-2 shadow-2xl hover:shadow-2xl'>
                                <BiStar className='text-5xl mt-2' />
                                <h2 className='text-center text-2xl font-semibold pt-3'>Get results</h2>
                                <p className='text-center pt-1'>Our capabilities cover everything from game ads to drag & drop concepts to other fully custom html5 banners.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-full bg-[#FBF8BE] flex justify-center'>
                    <div className='w-[95%] flex flex-col items-center my-20'>
                        <h1 className='text-5xl text-center font-bold w-[70%]'>The <span className='textColor'>Smart Choice </span> for Your Agency&apos;s Success</h1>
                        <p className='py-5 text-center text-xl font-semibold w-[80%] textColor'>
                            We know that creativity and innovation is key to advertising success. That's why we're always coming up with fresh and unique ideas to help our partners stand out in the crowded advertising landscape.
                        </p>

                        <div className='flex w-full my-10'>
                            <div className='w-[25%]'>
                                <img src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/649bccc5e46ebc6622d5fb26_Darius%20Tara%C5%A1kevi%C4%8Dius-p-500.png' alt='imgg' />
                            </div>
                            <div className='w-[70%] flex flex-col px-10'>
                                <p className='font-bold text-gray-500/90'>CASE STUDY</p>
                                <h2 className='py-5 text-5xl font-bold'>Company name: Challenging each other to grow</h2>
                                <p className='text-lg textColor'>
                                    Programmatic helps us provide our clients with out-of-the-box ideas and come up with amazing campaigns. Working with Eskimi, we value the challenge that we present to them, and they also present to us to come up with the best possible solutions to achieve our goals and meet client&apos;s expectations.
                                </p>

                                <div>
                                    <button className='px-6 py-2 border rounded-xl mt-5 bgColor text-white font-semibold'>Read the story</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreativeServices
