import React from 'react'

const CardSection = ({ image, heading, content, url }) => {
    return (
        <>
            <div className='w-[330px] shadow-md hover:bg-gradient-to-r from-purple-600 to-blue-600 hover:text-white hover:shadow-2xl hover:textColor'>
                <div className='w-full h-[200px] overflow-hidden'>
                    <img className='w-[100%] h-[100%] object-cover hover:scale-110 duration-200' src={image} alt='photo' />
                </div>
                <div className='py-2 px-4'>
                    <div>
                        <h2 className='font-bold text-2xl'>{heading}</h2>
                        <p className=' text-sm pt-2'>
                            {content}
                        </p>
                    </div>
                    <a href={url}>
                        <p className='py-5 underline text-sm text-blue-500'>Read more</p>
                    </a>
                </div>
            </div>
        </>
    )
}

export default CardSection
