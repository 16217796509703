import React from "react";
import Banner from "../Banner";

const VideoAdvertising = () => {
  return (
    <div className="w-full">
      <div className="w-full h-[40vh] md:h-[40vh] bgColor text-white flex flex-col justify-center items-center">
        <h1 className="w-[55%] text-3xl md:text-6xl text-center font-bold mt-10">
          BidBox Blog Corner
        </h1>
      </div>

      {/* <div className="w-full py-10 flex justify-center bg-white pb-5">
        <div className="w-[95%] flex justify-between items-center">
          <div className="w-[50%]">
            <h1 className="w-[80%] text-5xl font-bold textColor">
              Optimizing Video Advertising Campaigns with DSPs
            </h1>

            <div>
              <button></button>
            </div>
          </div>
          <div className="w-[50%]">
            <img
              className="mix-blend-darken w-[100%] h-[100%] object-cover"
              src="/blogpage5.jpeg"
              alt="blogImage"
            />
          </div>
        </div>
      </div> */}
      <div class="w-full py-10 flex justify-center bg-white pb-5">
    <div class="w-full md:w-[95%] flex flex-col md:flex-row justify-between items-center">
        <div class="w-full md:w-[50%] mb-5 md:mb-0">
            <h1 class="w-full md:w-[80%] text-2xl md:text-5xl font-bold textColor md:pl-0 pl-4">
                Optimizing Video Advertising Campaigns with DSPs
            </h1>

            <div>
                <button></button>
            </div>
        </div>

        <div class="w-full md:w-[50%]">
            <img class="mix-blend-darken w-full h-auto object-cover" src="/blogpage5.jpeg" alt="blogImage" />
        </div>
    </div>
</div>

      <div className="w-full flex flex-col items-center py-14">
        <div className="w-[95%]">
          <div className="mt-10">
            <div>
              <h1 className="textColor text-xl font-semibold">
                1. Understanding DSPs
              </h1>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Demand-Side Platforms (DSPs) are programmatic advertising
                platforms that enable advertisers to buy and manage ad inventory
                across various digital channels in real time. They offer a
                centralized hub for ad campaign management, allowing marketers
                to purchase and optimize ad space on a vast array of websites,
                apps, and platforms.
              </p>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Keywords: DSPs, Demand-Side Platforms, programmatic advertising,
                ad campaign management.
              </p>
            </div>
            <div className="mt-5">
              <h1 className="textColor text-xl font-semibold">
                2. Targeted Audience Reach
              </h1>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                One of the key advantages of using DSPs for video advertising is
                the ability to target your ads to specific audiences. DSPs offer
                advanced targeting options, such as demographic, geographic,
                behavioral, and contextual targeting. This precision ensures
                that your video ads are shown to the right people, increasing
                the chances of engagement and conversions.
              </p>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Keywords: Audience targeting, demographic targeting, geographic
                targeting, behavioral targeting, contextual targeting.
              </p>
            </div>
            <div className="mt-5">
              <h1 className="textColor text-xl font-semibold">
                3. Real-Time Optimization
              </h1>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                DSPs allow for real-time optimization of your video advertising
                campaigns. They use data-driven insights to adjust your ad
                placements, budgets, and creatives based on performance metrics.
                This continuous optimization ensures that your campaign stays on
                the right track, maximizing your return on investment (ROI).
              </p>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Keywords: Real-time optimization, data-driven insights, ad
                placements, budget optimization, creative optimization, ROI
              </p>
            </div>
            <div className="mt-5">
              <h1 className="textColor text-xl font-semibold">
                4. Ad Placement Variety
              </h1>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                DSPs give you access to a wide range of advertising inventory,
                including websites, apps, social media platforms, and video
                streaming services. This flexibility lets you experiment with
                different ad placements and discover the most effective channels
                for your video ads.
              </p>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Keywords: Ad placement, advertising inventory, video streaming
                services, social media platforms, ad variety.
              </p>
            </div>
            <div className="mt-5">
              <h1 className="textColor text-xl font-semibold">
                5. Comprehensive Reporting
              </h1>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                DSPs provide in-depth analytics and reporting tools to help you
                understand how your video ads are performing. You can track key
                performance indicators (KPIs) like click-through rates,
                conversion rates, and viewability, allowing you to make
                data-driven decisions to refine your campaigns further.
              </p>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Keywords: Analytics, reporting tools, KPIs, click-through rates,
                conversion rates, viewability.
              </p>
            </div>
            <div className="mt-5">
              <h1 className="textColor text-xl font-semibold">
                6. Cost Efficiency
              </h1>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                By using DSPs, you can manage your advertising spend more
                efficiently. These platforms often use programmatic buying,
                which means your ad purchases are automated and based on
                real-time bidding, ensuring that you get the best value for your
                budget.
              </p>
              <p className="w-[80%] text-gray-600 font-medium pt-3">
                Keywords: Cost efficiency, programmatic buying, automated ad
                purchases, real-time bidding.
              </p>
            </div>
          </div>

          <div className="w-full flex flex-col items-center mt-20">
            <h1 className="textColor text-center text-4xl font-semibold">
              Conclusion
            </h1>
            <p className="text-center textColor font-medium pt-5">
              Demand-Side Platforms (DSPs) have revolutionised the way video
              advertising campaigns are managed and optimised. With advanced
              targeting capabilities, real-time optimization, access to various
              ad placements, comprehensive reporting, and cost efficiency, DSPs
              are indispensable tools for digital marketers. By harnessing the
              power of DSPs, you can enhance the reach and impact of your video
              advertising campaigns, ultimately boosting your ROI.
            </p>
            <p className="text-center textColor font-medium pt-5">
              Incorporating DSPs into your video advertising strategy is a wise
              investment in the digital advertising landscape. Stay ahead of the
              competition and drive results by leveraging the capabilities of
              DSPs in your marketing efforts.
            </p>
          </div>
        </div>
      </div>

      <Banner />
    </div>
  );
};

export default VideoAdvertising;
