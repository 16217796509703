import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 lg:px-8 py-8">
      <h1 className="text-3xl lg:text-4xl font-bold mb-4 mt-10">Privacy Policy</h1>

      <p className="mb-4 lg:mb-8">
      At BidBox, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website and use our services.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">Information We Collect</h2>
      <p className="mb-4 lg:mb-4">
        We may collect personal information that you provide voluntarily when you:
        The types of personal information we may collect include but are not limited to:
      </p>
      <p className="mb-4 lg:mb-3">
      1. Register for an account
        
      </p>
      <p className="mb-4 lg:mb-3">
       2. Use our services
        
      </p>
      <p className="mb-4 lg:mb-3">
      3. Contact us for support or inquiries
        
      </p>
      <p className="mb-4 lg:mb-3">
      4. Participate in surveys or promotions
        
      </p>
      <p className="mb-4 lg:mb-4">
        The types of personal information we may collect include but are not limited to:
      </p>
      <p className="mb-4 lg:mb-3">
      1. Name
  
      </p>
      <p className="mb-4 lg:mb-3">
      2. Email address
        
      </p>
      <p className="mb-4 lg:mb-3">
      3. Company name
        
      </p>
      <p className="mb-4 lg:mb-3">
      4. Contact information
        
      </p>
      {/* Add more content here */}
      
      <h2 className="text-xl lg:text-2xl font-bold mb-2">How We Use Your Information</h2>
      <p className="mb-4 lg:mb-4">
        We may use the information we collect for various purposes, including but not limited to:
    
      </p>
      <p className="mb-4 lg:mb-3">
      1. Providing and maintaining our services
        
      </p>
      <p className="mb-4 lg:mb-3">
      2. Personalizing your experience
        
      </p>
      <p className="mb-4 lg:mb-3">
      3. Processing transactions
        
      </p>
      <p className="mb-4 lg:mb-3">
      4. Sending you administrative communications
        
      </p>
      <p className="mb-4 lg:mb-3">
      5. Responding to your inquiries and support requests
        
      </p>
      <p className="mb-4 lg:mb-3">
      6. Conducting research and analysis to improve our services
        
      </p>
      <p className="mb-4 lg:mb-3">
      7. Complying with legal obligations
        
      </p>
      {/* Add more content here */}

      <h2 className="text-xl lg:text-2xl font-bold mb-2">Data Security</h2>
      <p className="mb-4 lg:mb-6">
      We implement reasonable security measures to protect the confidentiality and integrity of your personal information. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>
      {/* Add more content here */}

      <h2 className="text-xl lg:text-2xl font-bold mb-2">Third-Party Disclosure</h2>
      <p className="mb-4 lg:mb-6">
      We may share your personal information with third parties only in the following circumstances:
      </p>
      {/* Add more content here */}

      <h2 className="text-xl lg:text-2xl font-bold mb-2">With your consent</h2>
      <p className="mb-4 lg:mb-3">
      To comply with legal obligations

      </p>
      <p className="mb-4 lg:mb-3">
      1. To enforce our policies and terms of service
        
      </p>
      <p className="mb-4 lg:mb-3">
      2. To protect the rights, property, or safety of BidBox, our users, or others
        
      </p>
      {/* Add more content here */}
      <h2 className="text-xl lg:text-2xl font-bold mb-2">Your Choices</h2>
      <p className="mb-4 lg:mb-6">
      You have the right to access, update, or delete your personal information at any time. You may also opt-out of receiving promotional communications from us by following the instructions provided in such communications.

      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">Changes to This Privacy Policy</h2>
      <p className="mb-4 lg:mb-6">
        We reserve the right to update or revise this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
      {/* Add more content here */}

      {/* <h2 className="text-xl lg:text-2xl font-bold mb-2">Contact Us</h2>
      <p className="mb-4 lg:mb-6">
        If you have any questions or concerns about this Privacy Policy, please contact us at : <a href="mailto:Team@zonemedia.co" className="text-blue-800 underline">Team@zonemedia.co</a>.
      </p> */}
      {/* Add more content here */}
    </div>
  );
};

export default PrivacyPolicy;
