import React from "react";
import CaseStudyCard from "./CaseStudyCard";

const Entertainment = () => {
  return (
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold text-center mt-14">
          Entertainment Platforms
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/zee 5.jpg"
                heading="Zee5's Successful Branding Journey"
                impression="Clicks : 175000+"
                vtr="Customer Acquired : 32500+"
                content="In a precise campaign aimed at enhancing ZEE5's brand awareness in the lively Indian market, our strategic focus centered on capturing attention and fostering engagement. Through innovative visuals and optimized campaigns, the campaign delivered outstanding results."
                content1="The campaign's success underscores the effectiveness of our approach in not only boosting brand awareness but also in driving meaningful interactions in a diverse and dynamic market."
                // content2="The campaign's success underscores the effectiveness of our approach in not only boosting brand awareness but also in driving meaningful interactions in a diverse and dynamic market."
              />
              <CaseStudyCard
                image="/disneyhotstar.jpg"
                heading="Hotstar's Cricket Carnival Success Story"
                impression="Clicks: 15 Mn+"
                vtr="Video Views: 6.7 Mn+"
                content="Our Hotstar campaigns during the Cricket World Cup 2023 were marked by a focus on India match days, aiming to boost video views. The challenge lay in rapidly attracting users for these one-day events, prompting us to implement real-time optimizations."
                content1="Precision targeting played a crucial role in swiftly enhancing viewership, ensuring the success of our strategy. The campaigns were a testament to our ability to navigate time constraints and deliver impactful results in a short duration."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
              <CaseStudyCard
                image="/LogoVidio.png"
                heading="Building Vidio's Success in Indonesia"
                impression="Impressions: 1.2 Mn+"
                vtr="Customer Acquired : 2750+ "
                content="Embarking on a strategic branding endeavor customized for the Indonesian market, Vidio aimed to enhance its brand visibility. Through a carefully crafted campaign, we precisely engaged the diverse Indonesian audience. The results were outstanding, with Click-Through Rates (CTR) and View-Through Rates (VTR) surpassing benchmarks, solidifying Vidio's market position."
                content1="The impressive campaign metrics serve as proof of the campaign's efficacy in not only elevating brand visibility but also resonating effectively with the target audience."
                // content2="The impressive CTR and VTR metrics serve as proof of the campaign's efficacy in not only elevating brand visibility but also resonating effectively with the target audience."
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                    Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entertainment;
