import React from 'react'
import NavBar from '../NavBar'
import Footer from '../Footer'
import Banner from '../Banner'

const About = () => {
    return (
        <>
            <div className='w-full'>
                <div className='w-full h-[40vh] md:h-[40vh] bgColor text-white flex  justify-center items-center'>
                    <h1 className='text-3xl md:text-6xl text-center font-bold mt-16'>Discover Our Journey</h1>
                </div>

                <div className='w-full md:h-screen bg-white flex flex-col items-center justify-center overflow-hidden'>

                    <div className='w-[95%] md:flex items-center'>
                        <div className='hidden md:block md:w-[50%] h-[450px]'>
                            <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
                        </div>

                        <div className='md:w-[40%] my-10 md:my-0 px-5 flex flex-col justify-between'>
                            <div>
                                <p className='text-lg text-center md:text-left text-gray-500 font-bold md:mb-5'>About Bidbox</p>
                                <h1 className='text-2xl md:text-4xl text-center md:text-left font-bold textColor md:pb-5'>Where innovation thrives!</h1>
                                <div className='md:hidden md:w-[50%] my-5'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
                                </div>
                                <p className='md:text-lg text-center md:text-left text-black'>
                                    Welcome to BidBox, your cutting-edge DSP (Demand-Side Platform) solution for programmatic advertising. We are dedicated to empowering advertisers with the tools and insights needed to reach their target audiences effectively.
                                </p>
                                <p className='md:text-lg text-center md:text-left text-black pt-4'>
                                    With BidBox, you gain access to a world of possibilities in the digital advertising realm, ensuring your brand&apos;s success in the ever-evolving landscape of programmatic advertising.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

                <Banner />
            </div>
        </>
    )
}

export default About
