import React from 'react'
import { Link } from 'react-router-dom'

const Cookie_Policy = () => {
    return (
        <div className='w-full'>
            <div className='w-full h-[70vh] md:h-[70vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold mt-16'>Cookie Policy</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>Reconnect. Convert. Succeed: The Retargeting Advantage. Rediscover engagement, drive conversions, and achieve marketing success with precision targeting.</p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Cookie_Policy
