import React, { useEffect, useState } from 'react'
import CardSection from './CardSection'
import Banner from '../Banner'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for styling
import { Link } from 'react-router-dom';

const Home = () => {

    useEffect(() => {
        AOS.init({
            duration: 500, // Animation duration in milliseconds
            delay: 50,   // Delay between elements
        });
    }, []);

    return (
        <>
            <div className='w-full overflow-hidden'>

                <div className='parallaxContainer w-full h-screen'>
                    <div className="video-background">
                        <video autoPlay muted loop className='w-full h-auto'>
                            <source src="/bitbox_bg.mp4" type="video/mp4" />
                        </video>
                    </div>

                    <div className='parallaxContent top-0 z-40 w-full'>


                        <div className='w-full flex flex-col items-center'>

                            <div className='w-[95%] mt-60 flex items-center justify-between'>
                                <div className='md:w-[50%] caption flex flex-col items-center md:flex-none md:items-start'>
                                    <h1 className='text-white  py-4 px-4 text-center md:text-left text-4xl md:text-6xl font-bold'>
                                        Unlocking Ad Potential, Delivering Success!
                                    </h1>
                                    <p className='text-white text-center px-4 md:text-left text-xl md:text-2xl font-medium pt-5'>
                                        Unified Demand-Supply Platform
                                    </p>
                                    <Link to='/contact'>
                                        <button className='mt-10 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl shadow-gray-900 hover:opacity-90'>
                                            Launch a Campaign
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full bg-white flex flex-col items-center overflow-hidden'>
                    <h1 className='text-center text-3xl md:text-6xl textColor font-bold mt-20'>Discover The Bidbox Hub</h1>
                    <div className='w-[95%] md:flex mb-10 md:mb-0 md:justify-between'>
                        <div className='w-full md:w-[40%] flex flex-col items-center md:items-start justify-center'>
                            <h1 className='text-xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3'>
                                Precision Targeting
                            </h1>
                            <div className='w-full md:hidden md:w-[60%]'>
                                <img className='w-[100%] h-[100%] object-cover' src='/TG1.jpg' alt='imagee' />
                            </div>
                            <p className='text-base md:text-xl text-center md:text-start text-black'>
                                Unleash the full potential of your ad campaigns with laser-focused targeting.
                            </p>
                            <p className='text-base md:text-xl text-center md:text-start text-black pt-2'>
                                Our cutting-edge tools ensure you connect with your ideal audience, leading to higher engagement and increased conversion rates.
                            </p>
                            <div className='mt-5'>
                                <a href='/contact'>
                                    <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                                </a>
                            </div>
                        </div>
                        <div className='w-full hidden md:block md:w-[60%]'>
                            <img className='w-[90%] h-[90%] object-cover' src='/TG1.jpg' alt='imagee' />
                        </div>
                    </div>

                    <div className='w-[95%] md:flex mb-10 md:mb-0 md:justify-between]'>
                        <div className='md:w-[55%] hidden md:flex'>
                            <img className='w-[85%] h-[90%] object-cover' src='/tg2.jpg' alt='image2' />
                        </div>

                        <div className='md:w-[40%] flex flex-col items-center md:items-start justify-center'>
                            <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3'>Instant ROI Insights</h1>

                            <div className='md:w-[55%] md:hidden'>
                                <img className='w-[100%] h-[100%] object-cover' src='/tg2.jpg' alt='image2' />
                            </div>
                            <p className='text-base md:text-xl text-center md:text-start text-black'>
                                Elevate your marketing strategy with our real-time ROI insights.
                            </p>
                            <p className='text-base md:text-xl text-center md:text-start text-black pt-2'>
                                Witness the magic as you optimize and refine your campaigns on the fly, delivering exceptional returns that exceed your expectations.
                            </p>
                            <div className='mt-5'>
                                <a href='/contact'>
                                    <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='w-[95%] mb-20 md:mb-32 md:flex md:justify-between'>
                        <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                            <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3'>
                                Intuitive Dashboard Excellence
                            </h1>
                            <div className='w-full md:hidden my-5 md:w-[50%]'>
                                <video autoPlay muted loop className='w-full h-auto'>
                                    <source src="/dashboard1.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <p className='text-base md:text-xl text-center md:text-start text-black'>
                                Intuitive Dashboard Excellence Experience the future of programmatic advertising at your fingertips.
                            </p>
                            <p className='text-base md:text-xl text-center md:text-start text-black pt-2'>
                                Our user-friendly dashboard is not just a tool; it's a game-changer. Customize, control, and conquer your advertising goals with ease, setting new standards for campaign management.
                            </p>
                            <div className='mt-5'>
                                <a href='/contact'>
                                    <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                                </a>
                            </div>
                        </div>
                        <div className='md:w-[50%] hidden md:block'>
                            <video autoPlay muted loop className='w-full h-auto'>
                                <source src="/dashboard1.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <Banner />

                    <div className='w-[95%] flex justify-between flex-wrap gap-10 md:mt-10'>
                        <div className='w-full rounded-xl px-8 py-3 pb-10 border shadow-lg overflow-hidden'>
                            <div className='my-10'>
                                <h1 className='text-3xl md:text-3xl textColor font-bold'>Exchanges & Direct Publishers</h1>
                                <h2 className='pt-5 pb-2 text-xl font-bold'>Integrated Supply Partners</h2>
                                <p className='textColor'>Unparalleled reach for global mobile and web inventories through leading direct
                                    programmatic integrations that enable higher audience outreach.
                                </p>
                            </div>

                            <div className='w-full flex flex-wrap gap-0 md:gap-5 lg:gap-10'>
                                <div className='flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/partner1.jpg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/partner2.jpg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/partner3.jpg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/partner4.jpg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/partner5.jpg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/partner8.jpg' />
                                </div>
                            </div>
                        </div>

                        {/* <div className='w-full rounded-xl px-8 py-3 pb-10 border shadow-lg overflow-hidden'>
                            <div className='my-10'>
                                <h1 className='text-3xl md:text-3xl textColor font-bold'>Verified Partners</h1>
                                <h2 className='pt-5 pb-2 text-xl font-bold'>Integrated Supply Partners</h2>
                                <p className='textColor'>Unparalleled reach for global mobile inventory through leading direct
                                    programmatic integrations that enable higher audience outreach.
                                </p>
                            </div>

                            <div className='w-full flex flex-wrap gap-5 md:gap-10'>
                                <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/aajtak.png' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/swiggy.png' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/zivame.jpeg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/tata.png' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/citroen.jpeg' />
                                </div>
                                <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
                                    <img className='w-[100%] h-[100%] object-contain' src='/zee5.png' />
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className='w-[95%] p-2 mt-10'>
                        <h1 className='text-center text-3xl md:text-6xl textColor font-bold my-10'>Latest Blog Posts</h1>
                        <div className='flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between pb-10'>
                            <CardSection url='/Programmatic-advertising' image="/blog1.jpeg" heading='The Power of Programmatic Advertising: A Deep Dive into DSPs' content="In the fast-paced world of digital marketing, staying ahead of the curve is crucial for success. One of the key tools that has revolutionized the advertising landscape is programmatic advertising. Within this realm, Demand-Side Platforms (DSPs) are the unsung heroes." />
                            <CardSection url='/Targeted-advertising' image="blog2.jpeg" heading='Effective Strategies for Targeted Advertising with DSPs' content="In today's digital age, the success of any advertising campaign depends on its ability to reach the right audience at the right time. One of the most effective tools for achieving this goal is through the use of Demand-Side Platforms (DSPs)." />
                            <CardSection url='/Data-driven-advertising' image="blog3.jpeg" heading='Maximizing ROI with Data-Driven Advertising through DSPs' content="In today's digital landscape, advertisers face an ever-growing challenge in ensuring their marketing efforts provide a strong return on investment (ROI). The key to success lies in harnessing the power of data-driven advertising through Demand-Side Platforms (DSPs)." />
                        </div>
                        <div className='flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between pb-10'>
                            <CardSection url='/cross-channel-advertising' image="blog4.jpeg" heading='The Role of DSPs in Cross-Channel Advertising Campaigns' content="In today's ever-evolving digital landscape, successful advertising campaigns require a strategic, cross-channel approach to reach the right audience at the right time. One of the key tools that has revolutionized the way advertisers manage and optimize their campaigns is the Demand-Side Platform (DSP)." />
                            <CardSection url='/future-of-digital-advertising' image="blog5.jpeg" heading='Navigating the Future of Digital Advertising: Trends in DSP Technology' content="In today's fast-paced digital landscape, the success of your advertising campaigns largely depends on staying ahead of the curve and harnessing the power of cutting-edge technologies. One such technology that plays a pivotal role in the world of digital advertising is the Demand-Side Platform (DSP)." />
                            <CardSection url='/video-advertising' image="blog6.jpeg" heading='Optimizing Video Advertising Campaigns with DSPs' content="In today's digital age, video advertising has become an integral part of marketing strategies. To ensure that your video ads reach the right audience at the right time, marketers often turn to Demand-Side Platforms (DSPs) for assistance." />
                        </div>
                    </div>

                </div>


                <div className='w-full md:h-screen bg-white flex flex-col items-center justify-center overflow-hidden'>

                    <div className='w-[95%] md:flex items-center'>
                        <div className='hidden md:block md:w-[50%] h-[450px]'>
                            <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
                        </div>

                        <div className='md:w-[40%] my-10 md:my-0 px-5 flex flex-col justify-between'>
                            <div>
                                <p className='text-lg text-center md:text-left text-gray-500 font-bold md:mb-5'>About Bidbox</p>
                                <h1 className='text-2xl md:text-4xl text-center md:text-left font-bold textColor md:pb-5'>Where innovation thrives!</h1>
                                <div className='md:hidden md:w-[50%] my-5'>
                                    <img className='w-[100%] h-[100%] object-contain' src='About.jpg' alt='imagee' />
                                </div>
                                <p className='md:text-lg text-center md:text-left text-black'>
                                    Welcome to BidBox, your cutting-edge DSP (Demand-Side Platform) solution for programmatic advertising. We are dedicated to empowering advertisers with the tools and insights needed to reach their target audiences effectively.
                                </p>
                                <p className='md:text-lg text-center md:text-left text-black pt-4'>
                                    With BidBox, you gain access to a world of possibilities in the digital advertising realm, ensuring your brand&apos;s success in the ever-evolving landscape of programmatic advertising.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default Home
