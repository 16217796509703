import React from 'react'

const Supply = () => {
    return (
        <>
            <div className='w-full'>
                <div className='relative w-full h-screen flex flex-col justify-center items-center bg-white'>
                    <div className='w-[95%] h-700px flex justify-between items-center mt-20'>
                        <div className='w-[40%]'>
                            <div>
                                <h1 className='text-4xl font-bold textColor'>Bridging Mobile Apps with Engaged Users</h1>
                                <p className='text-xl font-semibold pt-5 textColor'>Programmatic DSP for Mobile User Acquisition and Retargeting.</p>
                                <p className='text-xl pt-2 textColor'>Using our proprietary Machine Learning algorithms, we offer transparent, performance-driven, highly targeted UA and retargeting solutions at scale.</p>
                                <button className='py-2 px-6 border shadow-xl hover:scale-105 duration-100 mt-10 rounded-xl font-bold bgColor text-[#FBF8BE] text-lg'>Get in touch</button>
                            </div>
                        </div>
                        <div className='w-[55%] mt-10'>
                            <div>
                                <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63aaca14f3af9954c267ce7b_Image%20(2)-p-1600.png' alt='hero1' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex justify-center'>
                    <div className='w-[95%] flex flex-col my-20 justify-center'>
                        <h1 className='text-4xl font-bold text-center'>Programmatic Made Easy</h1>

                        <div className='w-full flex gap-10 mt-10'>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366415d125b7db6ad7f46ed_Transparency%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664428cb0f68cf2e5b1fe8_Target%20audience%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664433943c1ac401d20c78_Placement%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                        </div>

                        <div className='w-full flex gap-10 mt-10'>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366446818df79d2bd0b6825_Audience%20icon1.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664477bd2623d65822bf77_Mobile%20verification%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366447fcaac03448cecb3f6_Creatives%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-full md:h-screen bg-[#FBF8BE] flex flex-col justify-center items-center overflow-hidden'>

                    <div className='w-[95%] md:flex md:justify-center'>
                        <div className='md:w-[50%] h-[430px]'>
                            <img className='w-[100%] h-[100%] object-contain' src='/image1.png' />
                        </div>

                        <div className='md:w-[40%] flex flex-col justify-between'>
                            <div>
                                <p className='text-xl text-center md:text-left textColor font-bold mb-2'>About Us</p>
                                <h1 className='text-2xl md:text-4xl text-center md:text-left font-bold textColor pb-5'>Innovative Technology</h1>
                                <p className='md:text-xl text-center md:text-left textColor'>
                                    MediaSmart is at the forefront of technological innovation. We continually invest in research and development to bring you the latest advancements in media buying, ad formats, and targeting capabilities.
                                </p>
                                <p className='md:text-xl text-center md:text-left textColor pt-4'>
                                    We believe in building strong and lasting relationships with our clients.
                                </p>
                            </div>
                            <div className='my-10 flex gap-5'>
                                <div className='bg-white rounded-lg shadow-md hover:shadow-xl w-full md:h-[100px] box-border'>
                                    <h1 className='font-bold pl-2 pt-1 textColor'>Global Reach</h1>
                                    <p className='text-sm p-2 textColor'>With a global presence, MediaSmart can help you reach audiences around the world.</p>
                                </div>
                                <div className='bg-white rounded-lg shadow-md hover:shadow-xl w-full md:h-[100px] box-border'>
                                    <h1 className='font-bold pl-2 pt-1 textColor'>Customized Solutions</h1>
                                    <p className='text-sm p-2 textColor'>No two businesses are the same, and neither are their advertising needs.</p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Supply
