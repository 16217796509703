import React from "react";
import CaseStudyCard from "./CaseStudyCard";

const Gaming = () => {
  return (
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold  text-center mt-14">
          Fashion & Lifestyle
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/de.jpg"
                heading="Sparkling Diwali Moments with De Beers"
                impression="Impressions 3 Million+"
                vtr="VTR 97% +"
                content="In a strategic endeavor to capture the essence of Diwali, we partnered with the prestigious De Beers Forevermark Jewelry brand for a CTV campaign. Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band."
                content1="The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views. This case study illuminates our capability to deliver outstanding results for our partners, harnessing the allure of CTV during festive seasons to maximize brand engagement"
                // content2='This case study illuminates our capability to deliver outstanding results for our partners, harnessing the allure of CTV during festive seasons to maximize brand engagement.'
              />
              <CaseStudyCard
                image="/tata1.jpg"
                heading="Crafting opulent Luxury  with Tata CLiQ"
                impression="Impressions 3 Million+"
                vtr="VTR 97% +"
                content="In a targeted branding campaign for Tata Cliq - Time Vallee,  across top 6 metros, our primary goal was to enhance brand visibility and drive engagement for all premium watch lovers. Leveraging premium audiences, we crafted a campaign focused on acquiring maximum engagement. "
                content1="By harnessing the power of captivating visuals and compelling ad copy, we not only achieved our objective but surpassed expectations. The campaign garnered substantial impressions and clicks, solidifying Tata Cliq's presence in the competitive Indian market."
                content2="This case study exemplifies our ability to craft effective branding strategies tailored to specific geo-targets, ensuring impactful results for our clients."
              />
              <CaseStudyCard
                image="/hm.jpg"
                heading="H&M's Resonant Branding Triumph"
                impression="Impressions: 8 Million+"
                vtr="Viewability: 68%"
                content="Embarking on a branding journey for H&M in the dynamic Indian market, our mission was clear: amplify brand presence and garner engagement. With a keen focus on acquiring impressions and clicks, we curated a strategic campaign employing visually compelling content and enticing ad copy."
                content1="This success underscores our prowess in tailoring impactful branding strategies to resonate with the diverse Indian audience. H&M's case study stands as a testament to our ability to craft campaigns that transcend borders, leaving an indelible mark on the ever-evolving landscape of fashion and retail."
                // content2='Our objective was to intricately target a highly relevant audience through Connected TV (CTV) advertising, and our approach included the creation of a visually captivating L band. The outcome not only met but exceeded our expectations as we achieved an exceptional 97%+ View-Through Rate (VTR) and amassed over 3 million views.'
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                   Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gaming;
