import React from 'react'

const Omnichannel = () => {
    return (
        <>
            <div className='w-full'>
            <div className='relative w-full flex flex-col justify-center items-center bg-white'>
                    <div className='w-[95%] flex justify-between items-center mt-20'>
                        <div className='w-[40%]'>
                            <div>
                                <h1 className='text-4xl font-bold textColor'>Bridging Mobile Apps with Engaged Users</h1>
                                <p className='text-xl font-semibold pt-5 textColor'>Programmatic DSP for Mobile User Acquisition and Retargeting.</p>
                                <p className='text-xl pt-2 textColor'>Using our proprietary Machine Learning algorithms, we offer transparent, performance-driven, highly targeted UA and retargeting solutions at scale.</p>
                                <button className='py-2 px-6 border shadow-xl hover:scale-105 duration-100 mt-10 rounded-xl font-bold bgColor text-[#FBF8BE] text-lg'>Get in touch</button>
                            </div>
                        </div>
                        <div className='w-[50%] mt-10'>
                            <div>
                                <img className='w-[100%] h-[100%] object-contain' src='/image4.jpg' alt='hero1' />
                            </div>
                        </div>
                    </div>
                    <div className='w-[95%] flex justify-between items-center'>
                        
                        <div className='w-[50%] mt-10'>
                            <div>
                                <img className='w-[100%] h-[100%] object-contain' src='/image3.jpg' alt='hero1' />
                            </div>
                        </div>
                        <div className='w-[40%]'>
                            <div>
                                <h1 className='text-4xl font-bold textColor'>Bridging Mobile Apps with Engaged Users</h1>
                                <p className='text-xl font-semibold pt-5 textColor'>Programmatic DSP for Mobile User Acquisition and Retargeting.</p>
                                <p className='text-xl pt-2 textColor'>Using our proprietary Machine Learning algorithms, we offer transparent, performance-driven, highly targeted UA and retargeting solutions at scale.</p>
                                <button className='py-2 px-6 border shadow-xl hover:scale-105 duration-100 mt-10 rounded-xl font-bold bgColor text-[#FBF8BE] text-lg'>Get in touch</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-full flex justify-center'>
                    <div className='w-[95%] flex flex-col my-20 justify-center'>
                        <h1 className='text-4xl font-bold text-center'>Programmatic Made Easy</h1>

                        <div className='w-full flex gap-10 mt-10'>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366415d125b7db6ad7f46ed_Transparency%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664428cb0f68cf2e5b1fe8_Target%20audience%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664433943c1ac401d20c78_Placement%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                        </div>

                        <div className='w-full flex gap-10 mt-10'>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366446818df79d2bd0b6825_Audience%20icon1.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/63664477bd2623d65822bf77_Mobile%20verification%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                            <div className='w-full h-[300px] max-h-[300px] flex flex-col justify-evenly items-center px-5 shadow-md hover:shadow-2xl rounded-xl'>
                                <div className='w-20 h-20'>
                                    <img className='w-[100%] h-[100%] object-contain' src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/6366447fcaac03448cecb3f6_Creatives%20icon.svg' />
                                </div>
                                <h1 className='font-bold text-2xl text-center'>Brand Safety</h1>
                                <p className='textColor text-lg font-medium text-center'>Keep your reputation safe with Eskimi or integrated 3rd party platforms, such as DoubleVerify</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-full bg-[#FBF8BE] flex justify-center'>
                    <div className='w-[95%] flex flex-col items-center my-20'>
                        <h1 className='text-5xl text-center font-bold w-[70%]'>The <span className='textColor'>Smart Choice </span> for Your Agency&apos;s Success</h1>
                        <p className='py-5 text-center text-xl font-semibold w-[80%] textColor'>
                            We know that creativity and innovation is key to advertising success. That's why we're always coming up with fresh and unique ideas to help our partners stand out in the crowded advertising landscape.
                        </p>

                        <div className='flex w-full my-10'>
                            <div className='w-[25%]'>
                                <img src='https://global-uploads.webflow.com/60859154db955843c7fc06e7/649bccc5e46ebc6622d5fb26_Darius%20Tara%C5%A1kevi%C4%8Dius-p-500.png' alt='imgg' />
                            </div>
                            <div className='w-[70%] flex flex-col px-10'>
                                <p className='font-bold text-gray-500/90'>CASE STUDY</p>
                                <h2 className='py-5 text-5xl font-bold'>Company name: Challenging each other to grow</h2>
                                <p className='text-lg textColor'>
                                    Programmatic helps us provide our clients with out-of-the-box ideas and come up with amazing campaigns. Working with Eskimi, we value the challenge that we present to them, and they also present to us to come up with the best possible solutions to achieve our goals and meet client&apos;s expectations.
                                </p>

                                <div>
                                    <button className='px-6 py-2 border rounded-xl mt-5 bgColor text-white font-semibold'>Read the story</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Omnichannel
