import React from 'react'
import { Accordion } from 'flowbite-react'
import { BsArrowLeftSquareFill, BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import CardComponent from './CardComponent'
import CardModal from './CardModal'
import CardAccordion from './CardAccordion'

const CardDetails = () => {
    return (
        <>
            <div className='w-full bg-gray-100'>
                <div className='w-full flex flex-col items-center'>
                    <div className='w-[90%] my-5'>
                        <Link to="/credit-card">
                            <h3 className='font-medium flex items-center text-gray-600'>
                                <BsArrowLeftSquareFill className='mr-2 text-lg' /> Go Back
                            </h3>
                        </Link>
                    </div>

                    <div className='w-[90%] flex flex-wrap md:flex-nowrap gap-5 justify-between'>

                        <div className='w-full flex flex-col gap-10'>

                            <div className='bg-white py-2 border shadow-md rounded-xl flex flex-wrap md:flex-nowrap items-center justify-center md:justify-between'>
                                <div className='flex flex-wrap md:flex-nowrap w-[90%] md:w-full justify-center md:justify-normal'>
                                    <Link to="/card-details">
                                        <div className='w-full md:w-fit md:h-[180px] mt-2 md:mt-0 md:ml-2'>
                                            <img className='w-[100%] h-[100%] object-cover' alt='' src="https://d3po6s2ufk88fh.cloudfront.net/1000x0/filters:format(webp)/Select/CreditCards/8888b9755a9a43079ac5f272e21e4991.png" />
                                        </div>
                                    </Link>

                                    <div className='h-[180px] w-[330px] flex justify-between mt-2 md:mt-0 md:ml-3'>
                                        <div className='flex flex-col justify-between'>
                                            <div>
                                                <Link to="/card-details">
                                                    <h1 className='font-bold'>ICICI Bank Coral</h1>

                                                    <div className="flex items-center">
                                                        <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                        </svg>
                                                        <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                        </svg>
                                                        <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                        </svg>
                                                        <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                        </svg>
                                                        <svg className="w-4 h-4 text-gray-300 mr-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                        </svg>
                                                        <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                                                    </div>

                                                    <p className='text-gray-400 mt-2 text-sm'>1st year fee: <span className='text-blue-600'>0</span></p>
                                                </Link>
                                                <div className='flex flex-wrap gap-2 mt-2 box-border'>
                                                    <div className='border shadow-md'>
                                                        <span className='text-xs text-gray-600 p-2'>No Room Rent Limit</span>
                                                    </div>
                                                    <div className='border shadow-md'>
                                                        <span className='text-xs text-gray-600 p-2'>15 Lakh No Claim Bonus</span>
                                                    </div>
                                                    <div className='border shadow-md'>
                                                        <span className='text-xs text-gray-600 p-2'>Unlimited Restoration of Cover</span>
                                                    </div>
                                                    <div className=''>
                                                        {/* <span className='text-xs font-semibold cursor-pointer text-blue-500 p-2'>View all features</span> */}
                                                        <CardModal />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex gap-2'>
                                                <input type='checkbox' />
                                                <p className='text-sm font-semibold text-gray-500'>Add to compare</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center items-center my-5 md:mr-3 w-full md:w-[220px] '>
                                    <Link to="/proceed-to-buy">
                                        <button className='w-full md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                                            Apply Now!
                                        </button>
                                    </Link>
                                </div>
                            </div>


                            <div className='border rounded-xl shadow-md px-3 py-5 bg-white'>
                                <h2 className='text-xl font-bold'>What is the Axis Bank Vistara Credit Card?</h2>
                                <div className='flex items-center'>
                                    <p className='text-gray-500 pt-3'>
                                        If you are a frequent flyer who travels for pleasure or business, the Axis Bank Vistara credit card is just the card for you. The credit card comes with exclusive privileges and credit card benefits. With every swipe you make with the card, you can add Club Vistara credit points to your account. To know all about the Axis Bank Vistara credit card benefits, steps to apply, rewards such as the Axis Bank Vistara credit card lounge access, and more, continue reading.
                                    </p>
                                </div>


                            </div>

                            <div className='border rounded-xl shadow-md px-3 py-5 bg-white'>
                                <h2 className='text-xl font-bold'>Key Highlights of the Axis Bank Vistara Credit Card</h2>
                                <div className='flex items-center'>
                                    <p className='text-gray-500 pt-3'>
                                        If you fly frequently and want to enjoy the benefits of free class upgrades, complimentary air tickets, and priority check-ins, then the Axis Bank Vistara Credit Card is the perfect choice for you.
                                    </p>
                                </div>

                                <p className='text-gray-500 pt-3'>
                                    With this credit card, you get:
                                </p>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 shadow-lg cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className=''>
                                            <h2 className='text-blue-600 font-bold'>
                                                1.  Welcome Gift
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                As a grand gesture of welcoming you aboard, you are given a free business-class air ticket voucher. Moreover, you continue to get this benefit every time you renew your credit card.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 shadow-lg cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className=''>
                                            <h2 className='text-blue-600 font-bold'>
                                                2. Complimentary Membership of Club Vistara
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                On getting the Axis Bank Vistara Credit Card , you become a Base member of Club Vistara and enjoy a host of privileges that come with it. You can earn Club Vistara points (CV) and then redeem them to book free tickets, upgrade flights, and much more. Vistara&apos;s frequent flyer programme gives a chance to earn higher rewards while booking from their website. With more CV points, you climb from the &apos;Base&apos; tier to &apos;Platinum&apos; and unlock premium benefits and privileges.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 shadow-lg cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className=''>
                                            <h2 className='text-blue-600 font-bold'>
                                                3. Accrue Points on Spending
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                On every ₹200 spent with your Axis Bank Vistara Credit Card , you score 6 Club Vistara points. You can flexibly redeem or utilise these CV points to get free air tickets or flight upgrades.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 shadow-lg cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className=''>
                                            <h2 className='text-blue-600 font-bold'>
                                                4. Additional Rewards on Reaching Milestones
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                If you spend ₹1,00,000 in one year with your Axis Bank Vistara Credit Card, you get a bonus of 10,000 Club Vistara points. Further, on reaching annual milestones of ₹2,50,000, ₹5,00,000, ₹7,50,000 and ₹12,00,000, you get one complimentary business-class flight ticket for each milestone.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 shadow-lg cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className=''>
                                            <h2 className='text-blue-600 font-bold'>
                                                5. Lifestyle Privileges
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                With your Axis Bank Vistara Credit Card, you get free access to the participating lounges at select airports in India. Using Axis Bank’s Extraordinary Weekends programme, you can explore and experience unique places around the world. Moreover, you get a discount of at least 20% on visiting a wide range of partner restaurants throughout India with the Dining Delights programme.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='px-3 py-5 shadow-lg cursor-pointer border-gray-400 flex justify-between items-center rounded-lg border'>
                                        <div className=''>
                                            <h2 className='text-blue-600 font-bold'>
                                                6. Insurance Benefits
                                            </h2>
                                            <p className='text-gray-500 pt-1'>
                                                Enjoy a safe travel experience with the following benefits:
                                            </p>

                                            <ul className='text-gray-500 pt-1'>
                                                <li>1. Purchase protection insurance of up to ₹1 Lakh.</li>
                                                <li>2. Cover of up to $300 for loss of travel documents.</li>
                                                <li>3. Cover of up to $300 for loss or delay of your check-in bags.</li>
                                                <li>4. Coverage of ₹2.5 crores on accidental death during air travel.</li>
                                                <li>5. Lost credit card liability of up to 100% of your limit.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='border rounded-xl shadow-md px-3 py-5 mb-5 bg-white'>
                                <h2 className='text-xl font-bold pb-5'>FAQs on Axis Bank Vistara Credit Card</h2>
                                <Accordion>
                                    <Accordion.Panel>
                                        <Accordion.Title className='font-bold text-black'>
                                            Room rent limit
                                        </Accordion.Title>
                                        <Accordion.Content>
                                            <p className="mb-2 text-gray-500 dark:text-gray-400">
                                                <a
                                                    className="text-cyan-600 hover:underline dark:text-cyan-500"
                                                    href="https://flowbite.com/docs/getting-started/introduction/"
                                                >
                                                    <p>
                                                        What you should know
                                                    </p>
                                                </a>
                                                <p>
                                                    Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Look out for plans with No Room Rent limit
                                                </p>
                                            </p>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                    <Accordion.Panel>
                                        <Accordion.Title className='font-bold text-black'>
                                            Restoration of cover
                                        </Accordion.Title>
                                        <Accordion.Content>
                                            <p className="mb-2 text-gray-500 dark:text-gray-400">
                                                <p>
                                                    Rs 5 lakh unlimited times a year; for related and unrelated illness
                                                </p>
                                            </p>
                                            <p className="text-gray-500 dark:text-gray-400">

                                                <a
                                                    className="text-cyan-600 hover:underline dark:text-cyan-500"
                                                    href="https://flowbite.com/figma/"
                                                >
                                                    <p>
                                                        What you should know
                                                    </p>
                                                </a>
                                                <p>
                                                    Very useful feature, in case the sum Insured gets exhausted in a year due to multiple claims or a large claim. Insurer restores the sum insured to provide continued coverage to policyholders.
                                                </p>
                                            </p>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                    <Accordion.Panel>
                                        <Accordion.Title className='font-bold text-black'>
                                            No claim bonus
                                        </Accordion.Title>
                                        <Accordion.Content>
                                            <p className="mb-2 text-gray-500 dark:text-gray-400">
                                                <p>
                                                    Rs 50,000 per year; up to maximum of Rs 5 lakh
                                                </p>
                                            </p>
                                            <p className="text-gray-500 dark:text-gray-400">

                                                <a
                                                    className="text-cyan-600 hover:underline dark:text-cyan-500"
                                                    href="https://flowbite.com/figma/"
                                                >
                                                    <p>
                                                        What you should know
                                                    </p>
                                                </a>
                                                <p>
                                                    No Claim Bonus is the reward for not making a claim. Depending on plan, Insurer adds a 5%-100% bonus to the Sum Insured at no extra premium.
                                                </p>
                                            </p>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                    <Accordion.Panel>
                                        <Accordion.Title className='font-bold text-black'>
                                            No claim bonus
                                        </Accordion.Title>
                                        <Accordion.Content>
                                            <p className="mb-2 text-gray-500 dark:text-gray-400">
                                                <p>
                                                    Rs 50,000 per year; up to maximum of Rs 5 lakh
                                                </p>
                                            </p>
                                            <p className="text-gray-500 dark:text-gray-400">

                                                <a
                                                    className="text-cyan-600 hover:underline dark:text-cyan-500"
                                                    href="https://flowbite.com/figma/"
                                                >
                                                    <p>
                                                        What you should know
                                                    </p>
                                                </a>
                                                <p>
                                                    No Claim Bonus is the reward for not making a claim. Depending on plan, Insurer adds a 5%-100% bonus to the Sum Insured at no extra premium.
                                                </p>
                                            </p>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                </Accordion>
                            </div>

                        </div>

                        <div className='w-[500px] relative'>
                            <div className='sticky top-5 rounded-xl shadow-md px-5 py-3 bg-white'>
                                <h2 className='text-xl font-semibold'>Explore</h2>
                                <div className='border rounded-xl overflow-hidden my-5'>
                                    <img
                                        className='w-[100%] h-[100%] object-contain'
                                        src='https://www.bajajfinservmarkets.in/content/dam/bajajfinserv/website-revamp-banners/cards/EMI%20Card%202.jpg'
                                        alt='advertisement'
                                    />
                                </div>
                                <hr />
                                <h2 className='text-xl font-semibold mt-5'>Quick Links</h2>
                                <p className='mt-3 mb-5'>Credit Card | Credit Card | Eligibility Life time Free Credit Card | Shopping Credit Card | Travel Credit Card | Bajaj Finserv RBL Bank SuperCard | SBI Credit Card | Axis Bank Credit Card | IndusInd Credit Card |</p>
                                <hr />
                                <h2 className='text-xl font-semibold mt-5'>Popular Offer</h2>
                                <div className='border rounded-xl overflow-hidden my-5'>
                                    <img
                                        className='w-[100%] h-[100%] object-contain'
                                        src='https://www.bajajfinservmarkets.in/content/dam/bajajfinserv/website-revamp-banners/cibil/Credit-score_Desktop_367x122.jpg'
                                        alt='advertisement'
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardDetails
