import React from 'react'
import { LoanData } from './LoanData'
import LoanCardComponent from './LoanCardComponent'

const PersonalLoan = () => {
    console.log("loan data", LoanData)
    return (
        <>
            <div className='w-full'>
                <div className='relative w-full bg-black h-[200px] md:h-[300px] shadow-lg'>
                    <h1 className='absolute text-blue-900 md:text-5xl text-center left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-50 font-bold'>
                        PERSONAL LOAN
                    </h1>
                    <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' />
                </div>


                <div className='w-[90%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
                    <div className='w-fit flex flex-col gap-8 mt-5'>
                        <LoanCardComponent cardName="ICICI Bank Coral" cardImage="https://static.toiimg.com/thumb/msid-59385162,width-400,resizemode-4/59385162.jpg" />
                        <LoanCardComponent cardName="HDFC Bank Millenia" cardImage="https://images.hindustantimes.com/rf/image_size_640x362/HT/p2/2015/10/21/Pictures/hdfc-bank-logo-oficial-webiste_b8fd5eda-77f7-11e5-bc61-5d96ac0074a6.jpg" />
                        <LoanCardComponent cardName="HDFC Bank Infinia Metal Edition" cardImage="https://images.hindustantimes.com/rf/image_size_640x362/HT/p2/2015/10/21/Pictures/hdfc-bank-logo-oficial-webiste_b8fd5eda-77f7-11e5-bc61-5d96ac0074a6.jpg" />
                        <LoanCardComponent cardName="HDFC Bank Regalia" cardImage="https://images.hindustantimes.com/rf/image_size_640x362/HT/p2/2015/10/21/Pictures/hdfc-bank-logo-oficial-webiste_b8fd5eda-77f7-11e5-bc61-5d96ac0074a6.jpg" />
                        <LoanCardComponent cardName="Standard Chartered Super Value Titanium" cardImage="https://www.banking24seven.com/wp-content/uploads/2019/01/syanderd-charterd-credit-card.jpg" />
                        <LoanCardComponent cardName="Citi Bank Cashback" cardImage="https://fiu-original.b-cdn.net/fontsinuse.com/use-images/15/15727/15727.png?filename=citibank_twitter_profile_icon_v04.png" />
                    </div>

                    <div className='w-full relative mt-5'>
                        <div className='sticky overflow-hidden top-5 shadow-lg w-full rounded-lg bg-blue-500 border flex flex-col justify-between'>
                            
                            <div className='w-full mx-auto'>
                                <div className='w-full mx-auto'>
                                    <img src='https://creditloanz.com/wp-content/uploads/2022/12/HDFC-Bank-Personal-Loan.png' alt='imgg' />
                                </div>
                                <div className='bg-white'>
                                    <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <h1 className='my-5 text-center text-4xl font-extrabold'>PERSONAL LOAN</h1> */}
                <h4 className='mt-20 mb-5 text-center text-3xl text-blue-900 font-bold underline'>COMPARISON</h4>
                <div className='w-[90%] mx-auto'>
                    <table class="min-w-full table-auto">
                        <thead className='sticky top-0'>
                            <tr>
                                <th class="px-4 py-3 bg-blue-900 text-white"></th>
                                <th class="px-4 py-3 bg-blue-900 text-white">Bank Name</th>
                                <th class="px-4 py-3 bg-blue-900 text-white">Interest Range</th>
                                <th class="px-4 py-3 bg-blue-900 text-white">Loan Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {console.log("cccccc", LoanData)} */}
                            {
                                LoanData && LoanData.map((item, index) => {
                                    return <tr key={index} className='even:bg-gray-100 border hover:bg-blue-100'>
                                        <td class="font-medium text-center px-4 py-5">{index + 1}</td>
                                        <td class="font-medium text-center px-4 py-5">{item.Bank_Name}</td>
                                        <td class="text-center px-4 py-5">{item.Interest_Range}</td>
                                        <td class="text-center px-4 py-5">{item.Loan_Amount}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default PersonalLoan
