import React from 'react'

const BlogHarshita = () => {
    return (
        <>
            <div className='w-full'>
                
                <div className='w-full h-[60px] bg-black'>
                </div>

                <div className='relative w-full h-screen'>
                    <img className='w-[100%] h-[100%] object-cover' src='https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80' />
                </div>

                <div className='w-full flex flex-wrap justify-center md:flex-nowrap gap-10'>
                    <div className='md:w-[60%] flex flex-col items-center'>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                    </div>

                    <div className='md:w-[40%] flex flex-col items-center'>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                        <div className='w-[80%] mt-10'>
                            <div className=''>
                                <img className='w-[100%] h-[100%] object-cover' src='https://media.istockphoto.com/id/1146517111/photo/taj-mahal-mausoleum-in-agra.jpg?s=612x612&w=0&k=20&c=vcIjhwUrNyjoKbGbAQ5sOcEzDUgOfCsm9ySmJ8gNeRk=' />
                            </div>
                            <h1 className='py-5 text-xl font-semibold'>i love harshita</h1>
                        </div>
                    </div>
                </div>

                <div className='w-full'>
                    <div className='w-[90%] mx-auto my-10 h-[350px]'>
                        <img className='w-[100%] h-[100%] object-contain md:object-cover' src='https://img.freepik.com/free-psd/digital-marketing-facebook-banner-template_237398-233.jpg?w=2000'/>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BlogHarshita
