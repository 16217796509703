import React from 'react'
import Banner from '../Banner'
import { PiWechatLogoBold } from 'react-icons/pi'
import { BiLike, BiStar } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { GiThorHammer } from "react-icons/gi"
import { GiChoice } from "react-icons/gi";
import { TbSettingsCheck } from "react-icons/tb";
const Retargeting = () => {
    return (
        <div className='w-full'>
            <div className='w-full h-[70vh] md:h-[70vh] bgColor text-white flex flex-col justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold mt-16'>Winning with Retargeting</h1>
                <p className='w-[90%] md:text-lg text-center py-5'>Reconnect. Convert. Succeed: The Retargeting Advantage. Rediscover engagement, drive conversions, and achieve marketing success with precision targeting.</p>
                <Link to='/contact'>
                    <button className='mt-5 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl hover:opacity-90'>
                        Launch a Campaign
                    </button>
                </Link>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] flex flex-col items-center'>

                    <h1 className='pt-14 pb-5 text-3xl md:text-5xl textColor text-center font-bold md:w-[65%]'>
                        Reviving Dormant Audiences with Retargeting Campaigns
                    </h1>
                    {/* <p className='py-5 pb-7 text-center text-xl font-bold'>Our designers make your ideas happen based on data that comes from hundreds of successful campaigns.</p> */}

                    <div className='flex flex-wrap md:flex-nowrap w-full justify-between gap-10 my-10'>
                        <div className='w-full hover:bg-gradient-to-r from-purple-600 to-blue-600 hover:text-white textColor flex flex-col items-center rounded-3xl p-2 py-3 shadow-2xl hover:shadow-2xl'>
                            <GiChoice className='text-5xl mt-2' />
                            <h2 className='text-center text-2xl font-semibold pt-3'>Data Segmentation</h2>
                            <p className='text-center pt-1 text-black'>
                                Bidbox categorizes data for precise retargeting of clients' past brand-interacting user segments, ensuring marketing success.
                            </p>
                        </div>
                        <div className='w-full bg-gradient-to-r from-purple-600 to-blue-600 text-white flex flex-col items-center rounded-3xl p-2 py-3 hover:scale-105 md:scale-105 shadow-2xl'>
                            <GiThorHammer className='text-5xl mt-2' />
                            <h2 className='text-center text-2xl font-semibold pt-3'>Optimized Bidding</h2>
                            <p className='text-center pt-1 text-black'>
                                Define bid strategy, allocate budget, select ad inventory, and participate in real-time bidding with strategic bid adjustments.
                            </p>
                        </div>
                        <div className='w-full hover:bg-gradient-to-r from-purple-600 to-blue-600 hover:text-white textColor flex flex-col items-center rounded-3xl p-2 shadow-2xl hover:shadow-2xl'>
                            <TbSettingsCheck className='text-5xl mt-2' />
                            <h2 className='text-center text-2xl font-semibold pt-3'>Campaign Optimization</h2>
                            <p className='text-center pt-1 text-black'>
                                Continuously optimize campaign performance, adjusting bidding strategies and targeting for goal efficiency.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='w-full md:h-[95vh] bg-gradient-to-r from-purple-600 to-blue-600 py-10 md:pt-10 my-10 flex flex-col justify-center items-center'>
                <div className='w-[95%] text-white md:py-20 flex flex-col items-center'>
                    <h2 className='text-center text-3xl md:text-6xl font-bold'>Unlock Remarketing Benefits</h2>
                    <p className='md:w-[80%] py-5 md:py-10 text-gray-100 font-medium text-xl text-center'>Embrace the instrumental power of remarketing campaigns to rekindle interest, foster loyalty, and drive profitability.</p>
                    <div className='w-full flex justify-center flex-wrap gap-3 md:gap-8'>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>
                            Conversion Rate Improvement
                        </div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>
                            Brand Recall
                        </div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>
                            User Engagement
                        </div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>
                            Cost Efficiency
                        </div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>
                            Cart Abandonment Recovery
                        </div>
                        <div className='rounded shadow-2xl px-8 py-3 bg-white hover:bg-gradient-to-r from-purple-600 to-blue-600 text-black hover:text-white'>
                            Sales Uplift
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-col items-center'>

                <div className='w-[95%] mb-10 md:mb-32 lg:mb-0 md:flex md:justify-between'>
                    <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3'>
                            Remarketing Success Stories
                        </h1>
                        <div className='w-full md:hidden md:w-[50%]'>
                        <img className="w-[100%] h-[100%] object-cover" src='/retargeting.jpg' alt="img1"/>
                        </div>
                        <p className='text-base md:text-lg text-center md:text-start text-black'>
                            In our history, we've collaborated with industry giants like Swiggy and Zee Cinema, delivering exceptional results. Our remarketing campaigns not only boosted engagement for these renowned brands but also significantly enhanced their conversion rates, proving the effectiveness of our strategies.
                        </p>

                        <div className='mt-5'>
                            <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                        </div>
                    </div>
                    <div className='w-full hidden md:block md:w-[50%]'>
                    <img className="w-[100%] h-[100%] object-cover" src='/retargeting.jpg' alt="img1"/>
                        {/* <video autoPlay muted loop className='w-full h-auto'>
                            <source src="/dashboard1.mp4" type="video/mp4" />
                        </video> */}
                    </div>
                </div>

                <div className='w-[95%] mb-20 md:flex md:justify-between]'>
                    <div className='md:w-[55%] hidden md:flex'>
                        <img className='w-[100%] h-[100%] object-cover' src='/Optimization.jpg' alt='image2' />
                    </div>

                    <div className='md:w-[40%] flex flex-col items-center md:items-start justify-center'>
                        <h1 className='text-3xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3'>
                            Expert Remarketing Optimization
                        </h1>

                        <div className='md:w-[60%] md:hidden'>
                            <img className='w-[100%] h-[100%] object-cover' src='/Optimization.jpg' alt='image2Gif' />
                        </div>

                        <p className='text-base md:text-lg text-center md:text-start text-black'>
                            Unlock the full potential of your remarketing campaigns with our seasoned experts. Their vast experience and in-depth knowledge of running successful remarketing campaigns can help you fine-tune your strategy, maximize your ROI, and breathe new life into your marketing efforts.
                        </p>

                        <div className='mt-5'>
                            <button className='rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5'>Let&apos;s Start</button>
                        </div>
                    </div>
                </div>

            </div>

            <Banner />
        </div>
    )
}

export default Retargeting
